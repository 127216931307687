import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withStyles } from "@material-ui/core";
import { Col, FormGroup, Row } from "react-bootstrap";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2020Actions from "../../../../redux/W0X/W09F2020/W09F2020_actions";
import * as W75F2000Actions from "../../../../redux/W75/W75F2000/W75F2000_actions";
import { Combo } from "../../../common/form-material";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import { ButtonIcon, Tooltip } from 'diginet-core-ui/components';
import { Column } from "devextreme-react/data-grid";
import { Image } from "react-bootstrap";
import moment        from "moment";
import Filter        from "../../../filter/filter";
import _             from "lodash";
import UserImage      from "../../../common/user/user-image";
import UserName        from "../../../common/user/user-name";
import DateBoxPicker   from "../../../common/form-material/date-box";
import Api             from "../../../../services/api";
import W09F2022        from "../W09F2022/W09F2022";
import W09F2021        from "../W09F2021/W09F2021";
import Status          from "../../../common/status/status";
import Chip            from "@material-ui/core/Chip";
import PopoverW09F2020 from "../../../W0X/W09/W09F2020/W09F2020Popover";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
const styles = theme => ({
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    dateInfo: {
        display: "flex",
        alignItems: "center",
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757",
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
    },
    formDate: {
        "& > :first-child": {
            "& > :nth-child(1)": {
                paddingTop: Config.isMobile ? 0 : "3%",
                paddingRight: Config.isMobile ? 15 : 0,
                "& > div[class^='W09F2020-divText']": {
                    width: Config.isMobile ? "auto" : 95,
                },
            },
            "& > div:nth-child(2)": {
                paddingRight: Config.isMobile ? 15 : 5,
            },
            "& > div:nth-child(3)": {
                paddingLeft: Config.isMobile ? 15 : 5,
            },
        },
        "& > :last-child": {
            "& > :nth-child(1)": {
                paddingTop: Config.isMobile ? 0 : "3%",
                paddingRight: Config.isMobile ? 15 : 0,
                "& > div[class^='W09F2020-divText']": {
                    width: Config.isMobile ? "auto" : 95,
                },
            },
            "& > div:nth-child(2)": {
                paddingRight: Config.isMobile ? 15 : 5,
            },
            "& > div:nth-child(3)": {
                paddingLeft: Config.isMobile ? 15 : 5,
            },
        },
    },
    insideChild: {},
    danger: {
        color: "#fff",
        backgroundColor: theme.palette.danger.main
    },
    flexCenter:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    }
});
class W09F2020 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            cboLoading: false,
            cboEmployeeLoading: false,
            cboProjectLoading: false,
            showW09F2022: false,
            showW09F2021: false,
            disabled: false,
            mode: "add",
            rowData: null,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0,
            },
            iPermission: 0,
            iPermissionW09F2021: 0,
            DateFrom1: null,
            DateFrom2: null,
            DateTo1: null,
            DateTo2: null,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
        };
        this.filter = {
            employee: null,
            project: null,
            AppStatusID: "",
            DepartmentID: "",
            IsRegisterType: null,
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployee = {
            timer: null,
            search: "",
            skip: 0,
            limit: 50,
        };
        this.filterCboProject = {
            timer: null,
            search: "",
            skip: 0,
            limit: 50,
        };
        this.defaultParams = {
            FormID: "W09F2020",
            Language: Config.language || "84",
        };
        this.filterLoadID = null;
        this.refPopover = null;
    }

    handleResponseErr = (resErr) => {
        if (resErr) {
            const messageErr = _.get(resErr, "message", Config.lang("Loi_chua_xac_dinh"));
            Config.popup.show("INFO", messageErr);
        }
    };

    handleFilterChange = (key, data) => {
        const stateFieldName = ["DateFrom1", "DateFrom2", "DateTo1", "DateTo2"];
        const value = _.get(data, "value", "");
        if (_.includes(stateFieldName, key)) {
            this.setState({ [key]: value });
            return;
        }
        this.filter[key] = value;
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2020", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadPermissionOtherForm = async () => {
        await this.props.generalActions.getPermission(
            "W09F2021",
            (isPer) => {
                this.setState({ iPermissionW09F2021: isPer });
            },
            true,
        );
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            ...this.defaultParams,
            ..._.omit(this.filterCboEmployee, "timer"),
        };
        this.setState({ cboEmployeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (err, data) => {
            this.setState({ cboEmployeeLoading: false });
            this.handleResponseErr(err);
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            ...this.defaultParams,
            ..._.omit(this.filterCboProject, "timer"),
        };
        this.setState({ cboProjectLoading: true });
        this.props.generalActions.getCboProjects(param, (err, data) => {
            this.setState({ cboProjectLoading: false });
            this.handleResponseErr(err);
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    loadCboAppStatus = () => {
        const param = {
            ...this.defaultParams,
            FormID: "STANDARD",
        };
        this.setState({ cboLoading: true });
        this.props.generalActions.getCboAppStatus(param, (err) => {
            this.setState({ cboLoading: false });
            this.handleResponseErr(err);
        });
    };

    loadCboDepartment = () => {
        const param = {
            HostID: "",
            DivisionID: Config.getDivisionID(),
            ...this.defaultParams,
        };
        this.setState({ cboLoading: true });
        this.props.generalActions.getCboDepartments(param, (err) => {
            this.setState({ cboLoading: false });
            this.handleResponseErr(err);
        });
    };

    loadCboRegisterTypes = () => {
        this.setState({ cboLoading: true });
        this.props.w75f2000Actions.getCboRegisterType(err => {
            this.setState({ cboLoading: false });
            this.handleResponseErr(err);
        });
    };

    loadFormGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { DateFrom1, DateFrom2, DateTo1, DateTo2 } = this.state;
        const { employee, project } = this.filter;
        const params = {
            TransID: VoucherID || "",
            DivisionID: Config.getDivisionID(),
            HostID: "",
            ...this.defaultParams,
            DateFrom1,
            DateFrom2,
            DateTo1,
            DateTo2,
            ..._.omit(this.filter, ["employee", "project"]),
            EmployeeID: _.get(employee, "EmployeeID", ""),
            ProjectID: _.get(project, "ProjectID", ""),
            skip: _.toString(this.filter.skip),
            limit: _.toString(this.filter.limit),
        };
        this.setState({ gridLoading: true });
        this.props.w09f2020Actions.getFormGrid(params, (err) => {
            this.setState({ gridLoading: false });
            this.handleResponseErr(err);
        });
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getFormGrid} = this.props;
        Config.callChildForm({
            FormID: "W09F2020",
            // ID: voucher_id,
            keyExpr: "MaternityTransID",
            data: getFormGrid && getFormGrid.rows ? getFormGrid.rows : [],
            onLoad: (params) => this.loadFormGrid(params && params.ID),
            onAction: (e, data) => this.onUpdate({ data: data }, "showW09F2021")
        }, this.props);
    }

    renderEmpProfile = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const getCreateDate = _.get(data, "CreateDate", false);
        const date = getCreateDate ? moment.utc(getCreateDate).format("DD/MM/YYYY kk:mm:ss") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} />
                <div className={"w75f2000_info"}>
                    <div style={{ fontSize: "1.12rem" }}>
                        <UserName data={data} />
                    </div>
                    <div className={classes.dateInfo}>
                        <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                        <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                            {date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    formatDate = (date, typeFormat) => {
        if (!date) return "";
        if (moment(date).isValid()) {
            return moment(date).format(typeFormat);
        }
        return "";
    };

    renderInfo = (e) => {
        const { data } = e.row;
        const { classes } = this.props;
        const strFormatDate = "DD/MM/YYYY";
        const strFormatDate24h = "DD/MM/YYYY  kk:mm";
        const arrDataRender = [
            {
                title: Config.lang("Thoi_gian"),
                dataContent: _.join(
                    _.compact([this.formatDate(data.AbsentDateFrom, strFormatDate), this.formatDate(data.AbsentDateTo, strFormatDate)]),
                    " - ",
                ),
                isVisible: _.get(data, "AbsentDateTo", false) || _.get(data, "AbsentDateFrom", false),
            },
            {
                title: Config.lang("Ghi_chu"),
                dataContent: data.NoteU,
                isVisible: _.get(data, "NoteU", false),
            },
            {
                title: Config.lang("Nguoi_lap"),
                dataContent: data.CreateUserName,
                isVisible: _.get(data, "CreateUserName", false),
            },
            {
                title: Config.lang("Nguoi_duyet"),
                dataContent: _.join(
                    _.compact([data.ApproverName, data.DepartmentIDApp, this.formatDate(data.AppDate, strFormatDate24h)]),
                    " - ",
                ),
                isVisible:
                    _.get(data, "ApproverName", false) || _.get(data, "DepartmentIDApp", false) || _.get(data, "DepartmentIDApp", false),
            },
            {
                title: Config.lang("Ghi_chu_duyet"),
                dataContent: data.AppNote,
                isVisible: _.get(data, "AppNote", false),
            },
        ].filter((item) => item.isVisible);
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {arrDataRender.map((item, i) => (
                    <div key={i}>
                        {item.title}: {item.dataContent}
                    </div>
                ))}
                {Boolean(data.IsRegisterType) &&
                    <Chip label={Config.lang("Phieu_huy")}
                          color="primary"
                          className={classes.danger}
                          size="small"
                          style={{
                              fontSize:     '1rem',
                              fontWeight:   400,
                              borderRadius: 4
                          }}
                          variant={"default"}
                    />}
            </div>
        );
    };

    onHistoryApproval = (e, data) => {

        this.setState({ w84F3005PopupData: { TransID: data?data.MaternityTransID:null }, showW84F3005Popup: true })
    }

    onAdd = () => {
        this.setState({
            mode: "add",
            showW09F2021: true,
            rowData: null,
        });
    };

    onUpdate = (e, screenName) => {
        this.setState({
            [screenName]: true,
            rowData: e.data,
            mode: "edit",
        });
    };

    onCancel = (e, data) => {
        if(this.refPopover && e.currentTarget) {
            this.refPopover.onOpen(e.currentTarget, data);
        }
    };

    onClosePopover = () => {
        if(this.refPopover) {
            this.refPopover.onClose();
        }
    };

    onCloseModal = (screenName, isLoadingGrid = false) => {
        isLoadingGrid && this.loadFormGrid();
        this.setState({
            [screenName]: false,
        });
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadFormGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadFormGrid();
    };

    onOpenFilter = () => {
        if (this.filterLoadID) clearTimeout(this.filterLoadID);
        this.filterLoadID = setTimeout(() => {
            this.loadCboAppStatus();
            this.loadCboEmployees();
            this.loadCboDepartment();
            this.loadCboProjects();
            this.loadCboRegisterTypes();
        }, 300);
    };

    onDelete = ({ data, component: { deleteRow }, rowIndex }) => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            (async () => {
                this.setState({ gridLoading: true });
                const IsRegisterType = _.get(data, "IsRegisterType", 0);
                const res = await Api.put("/w09f2020/delete", { MaternityTransID: _.get(data, "MaternityTransID", ""), IsRegisterType });
                const status = !_.get(res, "data.Status", 1);
                const message = _.get(res, "data.Message", false);
                const messageErr = _.get(res, "message", false);
                this.setState({ gridLoading: false });
                if (status) {
                    Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                    deleteRow(rowIndex);
                    this.loadFormGrid();
                    return;
                }
                message && this.handleResponseErr({ message });
                messageErr && this.handleResponseErr({ message: messageErr })
            })()
        });
    };

    renderFilter = () => {
        const { getCboAppStatus, getCboDepartments, getRegisterTypes, classes } = this.props;
        const { AppStatusID, DepartmentID, IsRegisterType, employee, project } = this.filter;
        const {
            cboLoading,
            cboEmployeeLoading,
            cboProjectLoading,
            DateFrom1,
            DateFrom2,
            DateTo1,
            DateTo2,
            dataCboEmployees,
            dataCboProjects,
        } = this.state;
        return (
            <Filter
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onOpenFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup className={classes.formDate} style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col md={2} xs={12}>
                                        <div className={classes.divText}>{Config.lang("Ngay_bat_dau") + ":"}</div>
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ max: DateFrom2 }}
                                            onValueChanged={(e) => this.handleFilterChange("DateFrom1", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            value={DateFrom1}
                                        />
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ min: DateFrom1 }}
                                            onValueChanged={(e) => this.handleFilterChange("DateFrom2", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            value={DateFrom2}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2} xs={12}>
                                        <div className={classes.divText}>{Config.lang("Ngay_ket_thuc") + ":"}</div>
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ max: DateTo2 }}
                                            onValueChanged={(e) => this.handleFilterChange("DateTo1", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            value={DateTo1}
                                        />
                                    </Col>
                                    <Col md={5} xs={12}>
                                        <DateBoxPicker
                                            dateBoxProps={{ min: DateTo1 }}
                                            onValueChanged={(e) => this.handleFilterChange("DateTo2", e)}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            placeholder={"DD/MM/YYYY"}
                                            value={DateTo2}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={(e) => this.handleFilterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID}
                                            disabled={cboLoading}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Hinh_thuc")}
                                            dataSource={getRegisterTypes}
                                            displayExpr={"RegisterType"}
                                            valueExpr={"IsRegisterType"}
                                            onValueChanged={(e) => this.handleFilterChange("IsRegisterType", e)}
                                            showClearButton={true}
                                            value={IsRegisterType}
                                            disabled={cboLoading}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployee.skip}
                                            limit={this.filterCboEmployee.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={employee}
                                            stylingMode={"outlined"}
                                            loading={cboEmployeeLoading}
                                            label={Config.lang("Nhan_vien")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilterChange("employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployee.strSearch = e.target.value;
                                                this.filterCboEmployee.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployee.skip = e.skip;
                                                this.filterCboEmployee.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            valueExpr={"DepartmentID"}
                                            displayExpr={"DepartmentName"}
                                            onValueChanged={(e) => this.handleFilterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID}
                                            disabled={cboLoading}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{ marginBottom: "7px" }}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboProjects}
                                            skip={this.filterCboProject.skip}
                                            limit={this.filterCboProject.limit}
                                            displayExpr={"ProjectName"}
                                            keyExpr={"ProjectID"}
                                            valueExpr={"ProjectID"}
                                            value={project}
                                            loading={cboProjectLoading}
                                            stylingMode={"outlined"}
                                            label={Config.lang("Du_an")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.ProjectID + " - " + data.ProjectName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilterChange("project", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboProject.strSearch = e.target.value;
                                                this.filterCboProject.skip = 0;
                                                this.loadCboProjects(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProject.skip = e.skip;
                                                this.filterCboProject.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.loadFormGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderStatus = (e) => {
        const { data } = e.row;
        return (
            <Status data={data} />
        );
    };

    renderAction = (e) => {
        const { iPermission, iPermissionW09F2021 } = this.state;
        const { data } = e.row;
        const allowView = iPermission > 0;
        const allowDelete = iPermission > 3 && Number(data.AppStatusID) === 0;
        const allowUpdate = iPermissionW09F2021 > 1 && Number(data.AppStatusID) === 2 && data.IsUpdate !== 1 && Number(data.IsRegisterType) === 0;
        const allowCancel = iPermission > 3 && Number(data.AppStatusID) === 2 && data.IsUpdate !== 2 && Number(data.IsRegisterType) === 0;
        const buttonIconLists = [
            {
                title: Config.lang("Cap_nhat_sau_thai_san"),
                iconName:"More",
                disabled: !Boolean(allowUpdate),
                onClick: () => this.onUpdate(e, "showW09F2022")
            },
            {
                title: Config.lang("Lich_su_duyet"),
                iconName:"History",
                disabled: false,
                onClick: () => this.onHistoryApproval(e, data)
            },
            {
                title: Config.lang("Xem"),
                iconName:"View",
                disabled: !allowView,
                onClick: () => this.onUpdate(e, "showW09F2021")
            },
            {
                title: Config.lang("Xoa"),
                iconName:"Delete",
                disabled: !allowDelete,
                onClick: () =>  this.onDelete(e)
            },
            {
                title: Config.lang("Huy_phieu_"),
                iconName:"Cancel",
                disabled: !allowCancel,
                onClick: el =>  this.onCancel(el, data)
            },
        ]
        return (
            <div className={"display_row align-center"}>
                {_.map(buttonIconLists, (item,i) => (<Tooltip key={i} title={item.title}>
                    <ButtonIcon viewType={"text"}
                                disabled={item.disabled}
                                circular
                                onClick={item.onClick}
                                name={item.iconName} />
                </Tooltip>))}
            </div>
        );
    };

    render() {
        const { getFormGrid } = this.props;
        const { skip, limit } = this.filter;
        const { gridLoading, iPermission, showW09F2022, showW09F2021, rowData, mode,
                  dataPopover, anchorEl, type,showW84F3005Popup, w84F3005PopupData = {}
        } = this.state;
        return (
            <>
                <W84F3005 open={showW84F3005Popup} onClose={() => this.setState({ showW84F3005Popup: false })} FormID="W09F2020" TransID={w84F3005PopupData.TransID} />
                {showW09F2022 ? <W09F2022 open={showW09F2022} mode={mode} data={rowData} onClose={this.onCloseModal}/> : ""}
                {showW09F2021 ? <W09F2021 open={showW09F2021} mode={mode} data={rowData} onClose={this.onCloseModal}/> : ""}
                <PopoverW09F2020
                    ref={ref => this.refPopover = ref}
                    setLoading={isLoading => this.setState({gridLoading: isLoading})}
                    open={!!anchorEl}
                    onClose={this.onClosePopover}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    funcLoadGrid={this.loadFormGrid}
                />
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Nghi_thai_san")}>
                    <ButtonGeneral
                        disabled={!(iPermission > 1)}
                        name={Config.lang("Them")}
                        typeButton={"add"}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{ border: "none" }}
                    dataSource={_.get(getFormGrid, "rows", [])}
                    onDbCellClick={(e) => this.onUpdate(e, "showW09F2021")}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    rowAlternationEnabled={false}
                    noDataText={Config.lang("No_data")}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={_.get(getFormGrid, "total", 0)}
                    itemPerPage={limit}
                    keyExpr={"MaternityTransID"}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} width={420} />
                    <Column cellRender={this.renderInfo} />
                    <Column cellRender={this.renderStatus} />
                    <Column cellRender={this.renderAction} width={232} />
                </GridContainer>
            </>
        );
    }

    componentDidMount = async () => {
        await this.loadPermission();
        await this.loadPermissionOtherForm();
        if (!this.state.iPermission) return;
        this.loadFormGrid();
    };

    componentWillUnmount = () => {
        this.filterLoadID = null;
    };
}

export default compose(
    connect(
        (state) => ({
            getCboDepartments: state.general.getCboDepartments,
            getCboAppStatus: state.general.getCboAppStatus,
            getCboEmployees: state.general.getCboEmployees,
            getCboProjects: state.general.getCboProjects,
            getFormGrid: state.W09F2020.getFormGrid,
            getRegisterTypes: state.W75F2000.getRegisterTypes,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2020Actions: bindActionCreators(W09F2020Actions, dispatch),
            w75f2000Actions: bindActionCreators(W75F2000Actions, dispatch),
        }),
    ),
    withStyles(styles),
)(W09F2020);
