/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 5/7/2021
 * @Example
 */

import React, {useState, useEffect, useRef} from "react";
import { compose, bindActionCreators }      from "redux";
import { connect }                          from "react-redux";
import {MForm}                              from "../../../common/form-material";
import Config                               from "../../../../config";
import * as W09F2033Actions                 from "../../../../redux/W0X/W09F2033/W09F2033_actions";
import * as generalActions                  from "../../../../redux/general/general_actions";
import _                                    from "lodash";
import {
    Dropdown,
    MoneyInput,
    Typography,
    TextInput,
    Toggle,
    Attachment,
    Button,
    DatePicker,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'diginet-core-ui/components';
import CDN                                  from "../../../CDN";
import History                              from "../../../libs/history";

const initBaseSalary = () => {
    let data = {};
    for(let i = 1; i <= 4; i++) {
        data['BaseSalary0' + i] = null
    }
    return data;
};

const initSalCoefficient = () => {
    let data = {};
    for(let i = 1; i <= 20; i++) {
        data['SalCoefficient' + (i <= 9 ? "0" : "") + i] = null
    }
    return data;
};

const initOperatorLCB = () => {
    let data = {};
    for(let i = 1; i <= 4; i++) {
        data['OperatorLCB0' + i] = ""
    }
    return data;
};

const initOperatorHSL = () => {
    let data = {};
    for(let i = 1; i <= 20; i++) {
        data['OperatorHSL' + (i <= 9 ? "0" : "") + i] = ""
    }
    return data;
};

let paramFilterCboEmployee = {
    skip: 0,
    limit: 50,
    search: ""
};

const dataCboOperator = [
    {OperatorID: 0, OperatorName: "+"},
    {OperatorID: 1, OperatorName: "-"},
    {OperatorID: 2, OperatorName: "x"},
    {OperatorID: 3, OperatorName: "/"}
];

const initDataForm = {
    Employee: null,
    ValidDate: null,
    OperatorID: null,
    Reason: "",
    Notice : ""
};
const W09F2033 = (props) => {
    const {mode ,open, onClose, getSalCoefficients, getBaseSalaries, getCboReasons, rowData} = props;
    const [errorHelperText, _setErrorHelperText] = useState({});
    const [dataForm, setDataForm] = useState(initDataForm);
    const [loadingEmployees, setLoadingEmployees] = useState(false);
    const [loadingAttachments, setLoadingAttachments] = useState(false);
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [dataBaseSalary, setBaseSalary] = useState(initBaseSalary);
    const [dataSalCoefficient, setSalCoefficient] = useState(initSalCoefficient);
    const [operatorLCB, setOperatorLCB] = useState(initOperatorLCB);
    const [operatorHSL, setOperatorHSL] = useState(initOperatorHSL);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [isAllocate , setIsAllocate] = useState(false);
    const [disabled , setDisabled] = useState(false);
    const isClickSave = useRef(false);
    let attachments = useRef([]);
    let deletedFile = useRef([]);
    let checkedAllLocate = useRef(false);
    let dataOld = useRef([]);
    let allowSetDataCbo = useRef(false);
    const loadSalCoefficients = () => {
        props.w09f2033Actions.getSalcoefficientCaption( error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboReason = () => {
        props.w09f2033Actions.getCboReason( error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };


    const loadBaseSalaries = () => {
        props.w09f2033Actions.getBaseSalaryCaption( error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2030",
            Mode: 0,
            Language: Config.language || "84",
            ...paramFilterCboEmployee
        };
            setLoadingEmployees(true);
        props.generalActions.getCboEmployees(param, (error, data) => {
            setLoadingEmployees(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                setDataCboEmployees({
                    rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                    total: total
                })
            }
        });
    };

    const loadAttachments = () => {
        const param = {
            KeyID: _.get(rowData, "ProTransID", ""),
            TableName: "D09T2060"
        };
        setLoadingAttachments(true);
        props.generalActions.getAttachmentsByTransID(param, (err, data) => {
            setLoadingAttachments(false);
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };

    const filterData = (data, nameField) => {
        let obj = {};
        for(const [key, value] of Object.entries(data)){
            if(key.includes(nameField)) {
                obj[key] = value
            }
        }
        return obj;
    };

    const getNameOperator = (data, nameField, stateOperator) => {
        let dataOperator = {...stateOperator};
        Object.keys(data).forEach((item) => {
            if(item.includes(nameField)) {
                dataCboOperator.forEach((rs) => {
                    if(rs.OperatorName === data[item]) {
                        dataOperator[item] = rs.OperatorID
                    }
                })
            }
        });
        return dataOperator;
    };

    const loadForm = () => {
        const params = {
            Language: Config.language || 84,
            ProTransID: _.get(rowData, "ProTransID", "")
        };
        props.w09f2033Actions.getForm(params, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(data) {
                allowSetDataCbo.current = true;
                const dataEmployee = Config.getUser({EmployeeID: data?.EmployeeID || ""});
                if(dataOld.current) dataOld.current = {...data,EmployeeName: dataEmployee?.EmployeeName || ""};
                const operator = dataCboOperator.find(item => item.OperatorName === data?.Operator);
                const dataSalCoefficient = filterData(data, "SalCoefficient");
                const dataBaseSalary = filterData(data, "BaseSalary");
                const dataOperatorLCB = getNameOperator(data,"OperatorLCB", operatorLCB);
                const dataOperatorHSL = getNameOperator(data, "OperatorHSL", operatorHSL);
                setBaseSalary(dataBaseSalary);
                setSalCoefficient(dataSalCoefficient);
                setOperatorLCB(dataOperatorLCB);
                setOperatorHSL(dataOperatorHSL);
                setData({
                    ProTransID: data?.ProTransID || "",
                    Employee: data?.EmployeeID || "",
                    EmployeeName: dataEmployee?.EmployeeName || "",
                    ValidDate: data?.ValidDate || "",
                    OperatorID: operator?.OperatorID ?? null,
                    Reason: data?.Reason || "",
                    Notice: data?.Notice || "",
                });
                setIsAllocate(!!data.IsAllocate)
            }
        });
    };

    useEffect(() => {
        loadCboEmployees();
        loadSalCoefficients();
        loadCboReason();
        loadBaseSalaries();
        if(mode === "edit") {
            loadForm();
            loadAttachments();
            setDisabled(true)
        }
        return () => {
            paramFilterCboEmployee = {
                skip: 0,
                limit: 50,
                search: ""
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(() => {
        if(checkedAllLocate.current && !isAllocate) {
            allowSetDataCbo.current = false;
            checkedAllLocate.current = false;
            setOperatorLCB(initOperatorLCB);
            setOperatorHSL(initOperatorHSL);
            _setErrorHelperText({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllocate]);

    const onClosePopup = (isReload) => {
        if(onClose) onClose(isReload);
    };

    const setStateErrorText = (objValue) => {
        if(objValue) {
            setErrorHelperText({
                ...errorHelperText,
                ...objValue
            });
            return Object.keys(objValue).length !== 0;
        } else {
            setErrorHelperText({});
            return false;
        }
    };

    const setErrorHelperText = (obj) => {
        _setErrorHelperText({...errorHelperText, ...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        if (errorHelperText[key] && value) setErrorHelperText({[key]: ""});
        switch (key) {
            case "Employee":
                setData({Employee: value});
                break;
            case "OperatorID":
                setData({OperatorID: value});
                break;
            case "ValidDate":
                setData({ValidDate: value});
                break;
            case "Reason":
                setData({Reason: value});
                break;
            case "Notice":
                setData({Notice: value});
                break;
            default:
                break;
        }
    };

    const handleChangeBaseSalary = (key, e) => {
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        setBaseSalary({...dataBaseSalary,[key]:value})
    };

    const handleChangeOperatorLCB = (key, e) => {
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        if (errorHelperText[key] && value) setErrorHelperText({[key]: ""});
        setOperatorLCB({...operatorLCB,[key]:value})
    };

    const handleChangeSalCoefficient = (key, e) => {
            const value = _.get(e, 'value', _.get(e, 'target.value', ''));
            setSalCoefficient({...dataSalCoefficient,[key]:value})
    };

    const handleChangeOperatorHSL = (key, e) => {
        const value = _.get(e, 'value', _.get(e, 'target.value', ''));
        if (errorHelperText[key] && value) setErrorHelperText({[key]: ""});
        setOperatorHSL({...operatorHSL,[key]:value})
    };


    const onChangeAttachments = (e) => {
        attachments.current = e.allNewAttached ? e.allNewAttached : [];
        if (e.removedAttached && e.removedAttached.length > 0) {
            deletedFile.current = [ ...e.removedAttached];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            );
        }
    };

    const _getAttachments = (file) => {
        const dataFile = _.get(file, 'data.paths', []);
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    const setDataNull = (data) => {
        let obj = {};
        Object.keys(data).forEach(item => {
            if(data[item] === null || data[item] === "") {
                obj[item] = 0;
            } else {
                obj[item] = data[item]
            }
        });
        return obj;
    };


    const onSave = async () => {
        let validateRules = [
            {
                name: "Employee",
                rules: ["isRequired"],
                value: dataForm.Employee || ""
            },
            {
                name: "ValidDate",
                rules: ["isRequired"],
                value: dataForm.ValidDate || ""
            },
            {
                name: "OperatorID",
                rules: ["isRequired"],
                value: dataForm.OperatorID ?? ""
            },
        ];
        // check error dropdown operator null khi dataBaseSalary va dataSalCoefficient co data
        if(isAllocate) {
            Object.keys(dataBaseSalary).forEach(item => {
                let num = item.slice(-2);
                let nameOperatorLCB = "OperatorLCB" + num;
                if(nameOperatorLCB.includes(num) && item.includes(num)) {
                    if(dataBaseSalary[item] && !operatorLCB[nameOperatorLCB]) {
                        validateRules.push({
                            name: nameOperatorLCB,
                            rules: ["isRequired"],
                            value: operatorLCB[nameOperatorLCB] ?? ""
                        })
                    }
                }
            });
            Object.keys(dataSalCoefficient).forEach(item => {
                let num = item.slice(-2);
                let nameOperatorHSL = "OperatorHSL" + num;
                if(nameOperatorHSL.includes(num) && item.includes(num)) {
                    if(dataSalCoefficient[item] && !operatorHSL[nameOperatorHSL]) {
                        validateRules.push({
                            name: nameOperatorHSL,
                            rules: ["isRequired"],
                            value: operatorHSL[nameOperatorHSL] ?? ""
                        })
                    }
                }
            });
        }

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            setStateErrorText(validateForm);
            if(setStateErrorText(validateForm)) {
                isClickSave.current = true;
                return false;
            }
        }
        const dataResUploadFile = await _uploadFile(attachments.current, true);
        const arrAttachments = _getAttachments(dataResUploadFile);

        // create object name and value operatorLCB
        let dataOperatorLCB = {...operatorLCB};
        Object.keys(operatorLCB).forEach((item) => {
            const num = item.slice(-2);
            const nameOperatorLCB = "BaseSalary" + num;
            dataCboOperator.forEach((rs) => {
                if(dataBaseSalary[`${nameOperatorLCB}`] === null) {
                    dataOperatorLCB[item] = ""
                }
                else if(rs.OperatorID === operatorLCB[item]) {
                    dataOperatorLCB[item] = rs.OperatorName;
                }
            })
        });

        // create object name and value operatorHSL
        let dataOperatorHSL = {...operatorHSL};
        Object.keys(operatorHSL).forEach((item) => {
            const num = item.slice(-2);
            const nameOperatorHSL = "SalCoefficient" + num;
            dataCboOperator.forEach(rs => {
                if(dataBaseSalary[`${nameOperatorHSL}`] === null) {
                    dataOperatorHSL[item] = "";
                }
                else if(rs.OperatorID === operatorHSL[item]) {
                    dataOperatorHSL[item] = rs.OperatorName;
                }
            })
        });

        const baseSalary = setDataNull(dataBaseSalary);
        const salCoefficient = setDataNull(dataSalCoefficient);
        let operator =  dataCboOperator.find((rs) => rs.OperatorID === dataForm.OperatorID);
        let params = {
            Language: Config.language || 84,
            EmployeeID: dataForm.Employee,
            ValidDate: dataForm.ValidDate,
            Operator: operator.OperatorName,
            Reason: dataForm.Reason,
            Notice: dataForm.Notice,
            IsAllocate: Number(isAllocate),
            arrAttachment: JSON.stringify(arrAttachments),
            ...dataOperatorLCB,
            ...dataOperatorHSL,
            ...baseSalary,
            ...salCoefficient,
        };
        if(mode === "edit")  params = {...params, ProTransID: dataForm?.ProTransID || ""};
        props.w09f2033Actions[mode](params, async (error,data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data.Status === 1) {
                let message = data.Message;
                Config.popup.show('INFO', message);
                return false;
            }
            if(data.Status === 0) {
                Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
            if(mode === "add") params = {...params, ProTransID: data?.ProTransID || ""};
                await saveHistory({...params});
                onClosePopup(true)
            }
        });
    };

    const saveHistory = async (data) => {
        if(!data) return false;
        const ProTransID = _.get(data, "ProTransID", "");
        const EmployeeID = _.get(data, "EmployeeID", "");
        const Employee = dataCboEmployees.rows && dataCboEmployees.rows.length > 0 ?
                        dataCboEmployees.rows.find(item => item.EmployeeID === EmployeeID) : "";
        let isAllocate = data?.IsAllocate === 0 ?
                        _.get(data, "IsAllocate", "") + " - "+ Config.lang("Phan_bo_toan_tu_cho_tat_ca_du_lieu_luong") :
                        _.get(data, "IsAllocate", "") + " - "+ Config.lang("Phan_bo_mot_toan_tu_cho_tat_ca_du_lieu_luong");
        if (ProTransID) {
            // data Content
            let _data = {
                EmployeeID: EmployeeID,
                EmployeeName: _.get(Employee, "EmployeeName", ""),
                ValidDate: _.get(data, "ValidDate", ""),
                IsAllocate: isAllocate,
                Operator: _.get(data, "Operator", ""),
                Reason: _.get(data, "Reason", ""),
                Notice: _.get(data, "Notice", ""),
                BaseSalary01: _.get(data, "OperatorLCB01", "") + _.get(data, "BaseSalary01", ""),
                BaseSalary02: _.get(data, "OperatorLCB02", "") + _.get(data, "BaseSalary02", ""),
                BaseSalary03: _.get(data, "OperatorLCB03", "") + _.get(data, "BaseSalary03", ""),
                BaseSalary04: _.get(data, "OperatorLCB04", "") + _.get(data, "BaseSalary04", ""),
                SalCoefficient01: _.get(data, "OperatorHSL01", "") + _.get(data, "SalCoefficient01", ""),
                SalCoefficient02: _.get(data, "OperatorHSL02", "") + _.get(data, "SalCoefficient02", ""),
                SalCoefficient03: _.get(data, "OperatorHSL03", "") + _.get(data, "SalCoefficient03", ""),
                SalCoefficient04: _.get(data, "OperatorHSL04", "") + _.get(data, "SalCoefficient04", ""),
                SalCoefficient05: _.get(data, "OperatorHSL05", "") + _.get(data, "SalCoefficient05", ""),
                SalCoefficient06: _.get(data, "OperatorHSL06", "") + _.get(data, "SalCoefficient06", ""),
                SalCoefficient07: _.get(data, "OperatorHSL07", "") + _.get(data, "SalCoefficient07", ""),
                SalCoefficient08: _.get(data, "OperatorHSL08", "") + _.get(data, "SalCoefficient08", ""),
                SalCoefficient09: _.get(data, "OperatorHSL09", "") + _.get(data, "SalCoefficient09", ""),
                SalCoefficient10: _.get(data, "OperatorHSL10", "") + _.get(data, "SalCoefficient10", ""),
                SalCoefficient11: _.get(data, "OperatorHSL11", "") + _.get(data, "SalCoefficient11", ""),
                SalCoefficient12: _.get(data, "OperatorHSL12", "") + _.get(data, "SalCoefficient12", ""),
                SalCoefficient13: _.get(data, "OperatorHSL13", "") + _.get(data, "SalCoefficient13", ""),
                SalCoefficient14: _.get(data, "OperatorHSL14", "") + _.get(data, "SalCoefficient14", ""),
                SalCoefficient15: _.get(data, "OperatorHSL15", "") + _.get(data, "SalCoefficient15", ""),
                SalCoefficient16: _.get(data, "OperatorHSL16", "") + _.get(data, "SalCoefficient16", ""),
                SalCoefficient17: _.get(data, "OperatorHSL17", "") + _.get(data, "SalCoefficient17", ""),
                SalCoefficient18: _.get(data, "OperatorHSL18", "") + _.get(data, "SalCoefficient18", ""),
                SalCoefficient19: _.get(data, "OperatorHSL19", "") + _.get(data, "SalCoefficient19", ""),
                SalCoefficient20: _.get(data, "OperatorHSL20", "") + _.get(data, "SalCoefficient20", ""),
            };


            //name Content
            const captions = {
                EmployeeID: "Ma_nhan_vien",
                EmployeeName: "Ten_nhan_vien",
                ValidDate: "Ngay_hieu_luc",
                IsAllocate: "Phan_bo_toan_tu",
                Operator: "Toan_tu",
                Reason: "Ly_do",
                Notice: "Ghi_chu",
                BaseSalary01: "Luong_co_ban_%01%",
                BaseSalary02: "Luong_co_ban_%02%",
                BaseSalary03: "Luong_co_ban_%03%",
                BaseSalary04: "Luong_co_ban_%04%",
                SalCoefficient01: "He_so_luong_%01%",
                SalCoefficient02: "He_so_luong_%02%",
                SalCoefficient03: "He_so_luong_%03%",
                SalCoefficient04: "He_so_luong_%04%",
                SalCoefficient05: "He_so_luong_%05%",
                SalCoefficient06: "He_so_luong_%06%",
                SalCoefficient07: "He_so_luong_%07%",
                SalCoefficient08: "He_so_luong_%08%",
                SalCoefficient09: "He_so_luong_%09%",
                SalCoefficient10: "He_so_luong_%10%",
                SalCoefficient11: "He_so_luong_%11%",
                SalCoefficient12: "He_so_luong_%12%",
                SalCoefficient13: "He_so_luong_%13%",
                SalCoefficient14: "He_so_luong_%14%",
                SalCoefficient15: "He_so_luong_%15%",
                SalCoefficient16: "He_so_luong_%16%",
                SalCoefficient17: "He_so_luong_%17%",
                SalCoefficient18: "He_so_luong_%18%",
                SalCoefficient19: "He_so_luong_%19%",
                SalCoefficient20: "He_so_luong_%20%",
            };
            const _dataOld = {...dataOld.current};
            Object.keys(dataOld.current).forEach(item => {
                let num = item.slice(-2);
                if(!isNaN(num)) {
                    if(item.includes("BaseSalary")) {
                        _dataOld[item] = dataOld.current['OperatorLCB' + num] + dataOld.current[item];
                    }
                    if(item.includes("SalCoefficient")) {
                        _dataOld[item] = dataOld.current['OperatorHSL' + num] + dataOld.current[item];
                    }
                }
                if(item.includes("IsAllocate")) {
                    _dataOld[item] = dataOld?.current[item] === 0 ?
                            dataOld.current[item] + " - "+ Config.lang("Phan_bo_toan_tu_cho_tat_ca_du_lieu_luong") :
                            dataOld.current[item] + " - "+ Config.lang("Phan_bo_mot_toan_tu_cho_tat_ca_du_lieu_luong");
                }
            });
            const options = {
                data: _data,
                captions: captions,
                dataCompare: _dataOld,
                action: mode === "add" ? 0 : 1,
                ModuleID: "D09",
                TransID: ProTransID,
                TransactionID: "W09F2033",
                TransactionName: "Lập đề xuất điều chỉnh lương tăng giảm theo mức lương hiện tại",
            };

            const history = new History(options); //Init history
            await history.save();
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }

    };
    const disableControl = !(dataForm.Employee && dataForm.ValidDate && Number.isInteger(dataForm.OperatorID));
    return (
        <>
            <Modal
                open={open}
                width={"70%"}
                onClose={() => onClosePopup(false)}
            >
                <ModalHeader title={Config.lang("De_xuat_dieu_chinh_luong")} />
                <ModalBody>
                        <Row>
                            <Col xs={12}><Typography type={"h4"}>{Config.lang("Thong_tin_dieu_chinh")}</Typography></Col>

                            <Col xs={12} sm={12} md={6}>
                                <Dropdown
                                    error={errorHelperText && errorHelperText["Employee"]}
                                    loading={loadingEmployees}
                                    readOnly={disabled}
                                    label={Config.lang("Nhan_vien")}
                                    margin={"normal"}
                                    color={"primary"}
                                    dataSource={dataCboEmployees.rows}
                                    displayExpr={"EmployeeName"}
                                    valueExpr={"EmployeeID"}
                                    clearAble={true}
                                    allowSearch={true}
                                    required={true}
                                    valueObjectDefault={ mode === "edit" ? {
                                        EmployeeID: dataForm.Employee,
                                        EmployeeName: dataForm.EmployeeName
                                    } : null}
                                    limit={paramFilterCboEmployee.limit}
                                    total={dataCboEmployees.total}
                                    skip={paramFilterCboEmployee.skip}
                                    value={dataForm.Employee}
                                    onChange={e => handleChange("Employee", e)}
                                    renderItem={e => {
                                        if(!e) return null;
                                        const { data } = e;
                                        return data.EmployeeID + " - " + data.EmployeeName;
                                    }}
                                    onInput={e => {
                                        paramFilterCboEmployee.search = e.target.value;
                                        paramFilterCboEmployee.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={(e) => {
                                        paramFilterCboEmployee.skip = e.skip;
                                        paramFilterCboEmployee.limit = e.limit;
                                        loadCboEmployees();
                                    }}
                                    searchDelayTime
                                />
                            </Col>

                            <Col xs={12} sm={12} md={6}>
                                <DatePicker
                                    error={errorHelperText && errorHelperText["ValidDate"]}
                                    clearAble
                                    placeholder={"DD/MM/YYYY"}
                                    displayFormat={"DD/MM/YYYY"}
                                    required={true}
                                    label={Config.lang("Ngay_hieu_luc")}
                                    value={dataForm.ValidDate}
                                    onChange={e => handleChange("ValidDate", e)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6}>
                                <Dropdown
                                    error={errorHelperText && errorHelperText["OperatorID"]}
                                    label={Config.lang("Toan_tu")}
                                    disabled={isAllocate}
                                    margin={"normal"}
                                    color={"primary"}
                                    dataSource={dataCboOperator}
                                    displayExpr={"OperatorName"}
                                    valueExpr={"OperatorID"}
                                    clearAble={true}
                                    value={dataForm.OperatorID}
                                    required={true}
                                    onChange={e => handleChange("OperatorID", e)}
                                />
                            </Col>

                            <Col xs={12}>
                                <Typography type={"h4"} className={"mgt15 mgb15"}>
                                    {Config.lang("Thong_tin_luong")}
                                </Typography>
                            </Col>
                        </Row>
                        <div className={"display_row mgb10"}>
                            <Toggle
                                checked={isAllocate}
                                disabled={disableControl}
                                label={Config.lang("Ap_dung_toan_tu_theo_tung_du_lieu_luong")}
                                onChange={() => {
                                    checkedAllLocate.current = true;
                                    setIsAllocate(isAllocate => !isAllocate)
                                }}
                            />
                        </div>
                        <Row>
                            {getBaseSalaries && getBaseSalaries.map((data,idx) => {
                                let num = data.Code.slice(-2);
                                let nameOperatorLCB = "OperatorLCB" + num;
                                let nameField = "BaseSalary" + num;
                                let valueCbo = operatorLCB[nameOperatorLCB];
                                let valueInput = dataBaseSalary[nameField];
                                if(data.Disabled) return null;
                                return (
                                    <React.Fragment key={idx}>
                                    <Col xs={12} sm={12} md={6}>
                                        <Row>
                                            {isAllocate &&
                                                <Col xs={12} sm={12} md={4}>
                                                    <Dropdown
                                                        error={errorHelperText && errorHelperText[nameOperatorLCB]}
                                                        label={Config.lang("Toan_tu")}
                                                        margin={"normal"}
                                                        color={"primary"}
                                                        id={nameOperatorLCB}
                                                        dataSource={dataCboOperator}
                                                        displayExpr={"OperatorName"}
                                                        valueExpr={"OperatorID"}
                                                        clearAble={true}
                                                        value={valueCbo}
                                                        readOnly={disableControl}
                                                        onChange={e => handleChangeOperatorLCB(nameOperatorLCB, e)}
                                                    />
                                                </Col>
                                            }
                                            <Col xs={12} sm={12} md={isAllocate ? 8 : 12}>
                                                <MoneyInput
                                                    label={data?.CaptionName || ""}
                                                    readOnly={disableControl}
                                                    thousandSeparator={','}
                                                    decimalDigit={data?.Decimals || 0}
                                                    value={valueInput}
                                                    disabledNegative
                                                    onChange={e => handleChangeBaseSalary(nameField,e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    </React.Fragment>
                                )
                            })}

                            {getSalCoefficients && getSalCoefficients.map((data,idx) => {
                                let num = data.Code.slice(-2);
                                let nameField = "SalCoefficient" + num;
                                let nameOperatorHSL = "OperatorHSL" + num;
                                let valueCbo = operatorHSL[nameOperatorHSL];
                                let valueInput = dataSalCoefficient[nameField];
                                if(data.Disabled) return null;
                                return (
                                    <React.Fragment key={idx}>
                                        <Col xs={12} sm={12} md={6}>
                                            <Row>
                                                {isAllocate &&
                                                <Col xs={12} sm={12} md={4}>
                                                    <Dropdown
                                                        error={errorHelperText && errorHelperText[nameOperatorHSL]}
                                                        label={Config.lang("Toan_tu")}
                                                        margin={"normal"}
                                                        color={"primary"}
                                                        id={nameOperatorHSL}
                                                        dataSource={dataCboOperator}
                                                        displayExpr={"OperatorName"}
                                                        valueExpr={"OperatorID"}
                                                        style={{margin: "0 4px"}}
                                                        clearAble={true}
                                                        value={valueCbo}
                                                        readOnly={disableControl}
                                                        onChange={e => handleChangeOperatorHSL(nameOperatorHSL, e)}
                                                    />
                                                </Col>
                                                }
                                                <Col xs={12} sm={12} md={isAllocate ? 8 : 12}>
                                                    <MoneyInput
                                                        label={data?.CaptionName || ""}
                                                        readOnly={disableControl}
                                                        thousandSeparator={','}
                                                        decimalDigit={data?.Decimals || 0}
                                                        value={valueInput}
                                                        disabledNegative
                                                        onChange={e => handleChangeSalCoefficient(nameField,e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </React.Fragment>
                                )
                            })}

                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Dropdown
                                    label={Config.lang("Ly_do")}
                                    margin={"normal"}
                                    color={"primary"}
                                    dataSource={getCboReasons}
                                    displayExpr={"ReasonName"}
                                    valueExpr={"ReasonID"}
                                    value={dataForm.Reason}
                                    clearAble={true}
                                    onChange={e => handleChange("Reason", e)}
                                />
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextInput
                                    label={Config.lang("Ghi_chu")}
                                    value={dataForm.Notice}
                                    onChange={e => handleChange("Notice", e)}
                                />
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Attachment
                                    label={Config.lang("Dinh_kem")}
                                    maxFile={5}
                                    maxSize={"5mb"}
                                    multiple
                                    unitSize={"mb"}
                                    data={dataOldAttachments}
                                    disabled={loadingAttachments || (mode === "edit" && (+rowData?.AppStatusID > 0 && rowData?.AppStatusID !== ""))}
                                    onChange={(e) => {
                                        onChangeAttachments(e)
                                    }}
                                    uploadErrorInfo={{
                                        existingFile: Config.lang('File_da_duoc_dinh_kem'),
                                        maxFile: Config.lang('File_vuot_qua_so_luong_cho_phep'),
                                        maxSize: Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                                        fileType : Config.lang("File_khong_dung_dinh_dang"),
                                    }}

                                />
                            </Col>
                        </Row>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button
                            text={Config.lang("Luu")}
                            disabled={(mode === "edit" && (+rowData?.AppStatusID > 0 && rowData?.AppStatusID !== ""))}
                            viewType={"filled"}
                            color={"info"}
                            startIcon={"save"}
                            size={"large"}
                            onClick={onSave}
                        />
                    </div>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default compose(
    connect((state) => ({
            getSalCoefficients: state.W09F2033.getSalCoefficients,
            getBaseSalaries: state.W09F2033.getBaseSalaries,
            getCboReasons: state.W09F2033.getCboReasons,
        }),
        (dispatch) => ({
            w09f2033Actions: bindActionCreators(W09F2033Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2033);
