/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/28/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup, Image} from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {Combo, MForm, TextField} from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2032Actions from "../../../../redux/W0X/W09F2032/W09F2032_actions";
import DateBoxPicker from "../../../common/form-material/date-box"
import Attachments           from "../../../common/attachments/attachments";
import IconButton            from "@material-ui/core/IconButton";
import AttachFileIcon        from "@material-ui/icons/AttachFile";
import Modal                 from "../../../common/modal/modal";
import ExpansionPanel        from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import CDN                   from "../../../CDN";
import NumberFormat          from "react-number-format";
import moment                from "moment";
import { Dropdown, Typography, Avatar } from 'diginet-core-ui/components';
const styles = {
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
            '& .MuiButtonBase-root': {
                padding: '0 5px 0 0'
            }
        }
    },
    expandDetail: {
        display: 'block',
        padding: '0'
    },
    styleDisabled: {
        '& .dx-texteditor-input': {
            color: "#AAACB4"
        }
    }
};

class W09F2032Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermissionW09F5650: 0,
            showW09F2070Popup: false,
            tabKey: 0,
            isExpanded: false,
            disabled: false,
            error: {},
            loading: false,
            uploading: false,
            dataTeam: [],
            dataFormSalary: {},
            dataColumnSalary: [],
            dataOldAttachments: [],
            dataOldForm: {
                BaseSalary01: 0,
                BaseSalary02: 0,
                BaseSalary03: 0,
                BaseSalary04: 0,
                SalCoefficient01: 0,
                SalCoefficient02: 0,
                SalCoefficient03: 0,
                SalCoefficient04: 0,
                SalCoefficient05: 0,
                SalCoefficient06: 0,
                SalCoefficient07: 0,
                SalCoefficient08: 0,
                SalCoefficient09: 0,
                SalCoefficient10: 0,
                SalCoefficient11: 0,
                SalCoefficient12: 0,
                SalCoefficient13: 0,
                SalCoefficient14: 0,
                SalCoefficient15: 0,
                SalCoefficient16: 0,
                SalCoefficient17: 0,
                SalCoefficient18: 0,
                SalCoefficient19: 0,
                SalCoefficient20: 0,
            },
            dataForm: {
                ProTransID: "",
                Employee: null,
                ValidDate: null,
                ReasonID: "",
                Notice: "",
                NewBaseSalary01: 0,
                NewBaseSalary02: 0,
                NewBaseSalary03: 0,
                NewBaseSalary04: 0,
                NewSalCoefficient01: 0,
                NewSalCoefficient02: 0,
                NewSalCoefficient03: 0,
                NewSalCoefficient04: 0,
                NewSalCoefficient05: 0,
                NewSalCoefficient06: 0,
                NewSalCoefficient07: 0,
                NewSalCoefficient08: 0,
                NewSalCoefficient09: 0,
                NewSalCoefficient10: 0,
                NewSalCoefficient11: 0,
                NewSalCoefficient12: 0,
                NewSalCoefficient13: 0,
                NewSalCoefficient14: 0,
                NewSalCoefficient15: 0,
                NewSalCoefficient16: 0,
                NewSalCoefficient17: 0,
                NewSalCoefficient18: 0,
                NewSalCoefficient19: 0,
                NewSalCoefficient20: 0,
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            projectsLoading: false,
            employeeLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboProject = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
    }

    loadCaption = () => {
        this.setState({loading: true});
        this.props.w09F2032Actions.getCaption( error => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadCboReason = () => {
        this.setState({loading: true});
        this.props.w09F2032Actions.getCboReason( error => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };


    loadCboCode = () => {
        this.setState({loading: true});
        this.props.w09F2032Actions.getCboCode( error => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadForm = (EmployeeID) => {
        const {mode, data} = this.props;
        const param = {
            FormID: "W09F2032",
            Language: Config.language || 84,
            ProTransID: (mode === "edit" || mode === "view") ? data && data.ProTransID : "09PT0A000000011",
            Mode: (mode === "edit" || mode === "view") ? 1 : 0,
            EmployeeID: EmployeeID || Config.getHREmployeeID(),
        };
        this.setState({loading: true});
        this.props.w09F2032Actions.getForm(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataOldForm: {
                        BaseSalary01: data.BaseSalary01 || data.BaseSalary01 === 0 ? Number(data.BaseSalary01) : 0,
                        BaseSalary02: data.BaseSalary02 || data.BaseSalary02 === 0 ? Number(data.BaseSalary02) : 0,
                        BaseSalary03:data.BaseSalary03 || data.BaseSalary03 === 0 ? Number(data.BaseSalary03) : 0,
                        BaseSalary04: data.BaseSalary04 || data.BaseSalary04 === 0 ? Number(data.BaseSalary04) : 0,
                        SalCoefficient01: data.SalCoefficient01 || data.SalCoefficient01 === 0 ? Number(data.SalCoefficient01) : 0,
                        SalCoefficient02: data.SalCoefficient02 || data.SalCoefficient02 === 0 ? Number(data.SalCoefficient02) : 0,
                        SalCoefficient03: data.SalCoefficient03 || data.SalCoefficient03 === 0 ? Number(data.SalCoefficient03) : 0,
                        SalCoefficient04: data.SalCoefficient04 || data.SalCoefficient04 === 0 ? Number(data.SalCoefficient04) : 0,
                        SalCoefficient05: data.SalCoefficient05 || data.SalCoefficient05 === 0 ? Number(data.SalCoefficient05) : 0,
                        SalCoefficient06: data.SalCoefficient06 || data.SalCoefficient06 === 0 ? Number(data.SalCoefficient06) : 0,
                        SalCoefficient07: data.SalCoefficient07 || data.SalCoefficient07 === 0 ? Number(data.SalCoefficient07) : 0,
                        SalCoefficient08: data.SalCoefficient08 || data.SalCoefficient08 === 0 ? Number(data.SalCoefficient08) : 0,
                        SalCoefficient09: data.SalCoefficient09 || data.SalCoefficient09 === 0 ? Number(data.SalCoefficient09) : 0,
                        SalCoefficient10: data.SalCoefficient10 || data.SalCoefficient10 === 0 ? Number(data.SalCoefficient10) : 0,
                        SalCoefficient11: data.SalCoefficient11 || data.SalCoefficient11 === 0 ? Number(data.SalCoefficient11) : 0,
                        SalCoefficient12: data.SalCoefficient12 || data.SalCoefficient12 === 0 ? Number(data.SalCoefficient12) : 0,
                        SalCoefficient13: data.SalCoefficient13 || data.SalCoefficient13 === 0 ? Number(data.SalCoefficient13) : 0,
                        SalCoefficient14: data.SalCoefficient14 || data.SalCoefficient14 === 0 ? Number(data.SalCoefficient14) : 0,
                        SalCoefficient15: data.SalCoefficient15 || data.SalCoefficient15 === 0 ? Number(data.SalCoefficient15) : 0,
                        SalCoefficient16: data.SalCoefficient16 || data.SalCoefficient16 === 0 ? Number(data.SalCoefficient16) : 0,
                        SalCoefficient17: data.SalCoefficient17 || data.SalCoefficient17 === 0 ? Number(data.SalCoefficient17) : 0,
                        SalCoefficient18: data.SalCoefficient18 || data.SalCoefficient18 === 0 ? Number(data.SalCoefficient18) : 0,
                        SalCoefficient19: data.SalCoefficient19 || data.SalCoefficient19 === 0 ? Number(data.SalCoefficient19) : 0,
                        SalCoefficient20: data.SalCoefficient20 || data.SalCoefficient20 === 0 ? Number(data.SalCoefficient20) : 0,
                    },
                    dataForm: {
                        ...this.state.dataForm,
                        ProTransID: data.ProTransID ? data.ProTransID : null,
                        ValidDate: data.ValidDate ? data.ValidDate : null,
                        ReasonID: data.Reason ? data.Reason : "",
                        Notice: data.Notice ? data.Notice : "",
                        NewBaseSalary01: data.NewBaseSalary01 ? data.NewBaseSalary01 : 0,
                        NewBaseSalary02: data.NewBaseSalary02 ? data.NewBaseSalary02 : 0,
                        NewBaseSalary03: data.NewBaseSalary03 ? data.NewBaseSalary03 : 0,
                        NewBaseSalary04: data.NewBaseSalary04 ? data.NewBaseSalary04 : 0,
                        NewSalCoefficient01: data.NewSalCoefficient01 ? data.NewSalCoefficient01 : 0,
                        NewSalCoefficient02: data.NewSalCoefficient02 ? data.NewSalCoefficient02 : 0,
                        NewSalCoefficient03: data.NewSalCoefficient03 ? data.NewSalCoefficient03 : 0,
                        NewSalCoefficient04: data.NewSalCoefficient04 ? data.NewSalCoefficient04 : 0,
                        NewSalCoefficient05: data.NewSalCoefficient05 ? data.NewSalCoefficient05 : 0,
                        NewSalCoefficient06: data.NewSalCoefficient06 ? data.NewSalCoefficient06 : 0,
                        NewSalCoefficient07: data.NewSalCoefficient07 ? data.NewSalCoefficient07 : 0,
                        NewSalCoefficient08: data.NewSalCoefficient08 ? data.NewSalCoefficient08 : 0,
                        NewSalCoefficient09: data.NewSalCoefficient09 ? data.NewSalCoefficient09 : 0,
                        NewSalCoefficient10: data.NewSalCoefficient10 ? data.NewSalCoefficient10 : 0,
                        NewSalCoefficient11: data.NewSalCoefficient11 ? data.NewSalCoefficient11 : 0,
                        NewSalCoefficient12: data.NewSalCoefficient12 ? data.NewSalCoefficient12 : 0,
                        NewSalCoefficient13: data.NewSalCoefficient13 ? data.NewSalCoefficient13 : 0,
                        NewSalCoefficient14: data.NewSalCoefficient14 ? data.NewSalCoefficient14 : 0,
                        NewSalCoefficient15: data.NewSalCoefficient15 ? data.NewSalCoefficient15 : 0,
                        NewSalCoefficient16: data.NewSalCoefficient16 ? data.NewSalCoefficient16 : 0,
                        NewSalCoefficient17: data.NewSalCoefficient17 ? data.NewSalCoefficient17 : 0,
                        NewSalCoefficient18: data.NewSalCoefficient18 ? data.NewSalCoefficient18 : 0,
                        NewSalCoefficient19: data.NewSalCoefficient19 ? data.NewSalCoefficient19 : 0,
                        NewSalCoefficient20: data.NewSalCoefficient20 ? data.NewSalCoefficient20 : 0,
                    }
                }, () => {
                    if(mode === "edit" || mode === "view") {
                        const user = Config.getUser({EmployeeID: data?.EmployeeID});
                        this.setState({dataForm: {
                                ...this.state.dataForm,
                                Employee: user
                            }});
                    }

                });
            }
        });
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {mode} = this.props;
        switch (key) {
            case "EmployeeID":
                this._setDataForm("Employee", e.data, () => {
                    const EmployeeID = e?.data?.EmployeeID || "";
                    if (EmployeeID && mode === 'add') {
                        this.loadForm(EmployeeID);
                    }
                });
                break;
            case "ValidDate":
                this._setDataForm(key, e.value);
                break;
            case "ReasonID":
                this._setDataForm(key, e.value);
                break;
            case "Notice":
                this._setDataForm(key, e.target.value);
                break;
            default:

                this._setDataForm(key, e.value);
                break;
        }
    };

    componentDidMount = () => {
        const {mode, data} = this.props;
        this.loadCaption();
        this.loadCboCode();
        this.loadCboReason();
        this.loadCboEmployees();
        if (mode === 'edit' || mode === 'view') {
            this.loadAttachments();
            if(data && Number(data.AppStatusID) !== 0) {
                this.setState({disabled: true});
            }
            this.loadForm(data?.EmployeeID);
        }
    };

    _setDataForm = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        }, () => {
            if (cb) cb();
        });
    };

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...(this.state[stateError] ? this.state[stateError] : {}),
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = () => {
        const {mode} = this.props;
        const {dataForm, dataOldForm} = this.state;
        //validate form..
        const validateRules = [
            {
                name: "EmployeeID",
                rules: "isRequired",
                value: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : ""
            },
            {
                name: "ValidDate",
                rules: "isRequired",
                value: dataForm && dataForm.ValidDate ? dataForm.ValidDate : ""
            },

        ];

        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const arrAttachments = this._getAttachments();
            let params = {
                ProTransID: dataForm && dataForm.ProTransID ? dataForm.ProTransID : "",
                EmployeeID: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : "",
                ValidDate: dataForm && dataForm.ValidDate ? moment(dataForm.ValidDate).format("YYYY-MM-DD") : null,
                ReasonID: dataForm && dataForm.ReasonID ? dataForm.ReasonID : "",
                Notice: dataForm && dataForm.Notice ? dataForm.Notice : "",
                NewBaseSalary01: dataForm && dataForm.NewBaseSalary01 ? Number(dataForm.NewBaseSalary01) : 0,
                NewBaseSalary02: dataForm && dataForm.NewBaseSalary02 ? Number(dataForm.NewBaseSalary02) : 0,
                NewBaseSalary03: dataForm && dataForm.NewBaseSalary03 ? Number(dataForm.NewBaseSalary03) : 0,
                NewBaseSalary04: dataForm && dataForm.NewBaseSalary04 ? Number(dataForm.NewBaseSalary04) : 0,
                NewSalCoefficient01: dataForm && dataForm.NewSalCoefficient01 ? Number(dataForm.NewSalCoefficient01) : 0,
                NewSalCoefficient02: dataForm && dataForm.NewSalCoefficient02 ? Number(dataForm.NewSalCoefficient02) : 0,
                NewSalCoefficient03: dataForm && dataForm.NewSalCoefficient03 ? Number(dataForm.NewSalCoefficient03) : 0,
                NewSalCoefficient04: dataForm && dataForm.NewSalCoefficient04 ? Number(dataForm.NewSalCoefficient04) : 0,
                NewSalCoefficient05: dataForm && dataForm.NewSalCoefficient05 ? Number(dataForm.NewSalCoefficient05) : 0,
                NewSalCoefficient06: dataForm && dataForm.NewSalCoefficient06 ? Number(dataForm.NewSalCoefficient06) : 0,
                NewSalCoefficient07: dataForm && dataForm.NewSalCoefficient07 ? Number(dataForm.NewSalCoefficient07) : 0,
                NewSalCoefficient08: dataForm && dataForm.NewSalCoefficient08 ? Number(dataForm.NewSalCoefficient08) : 0,
                NewSalCoefficient09: dataForm && dataForm.NewSalCoefficient09 ? Number(dataForm.NewSalCoefficient09) : 0,
                NewSalCoefficient10: dataForm && dataForm.NewSalCoefficient10 ? Number(dataForm.NewSalCoefficient10) : 0,
                NewSalCoefficient11: dataForm && dataForm.NewSalCoefficient11 ? Number(dataForm.NewSalCoefficient11) : 0,
                NewSalCoefficient12: dataForm && dataForm.NewSalCoefficient12 ? Number(dataForm.NewSalCoefficient12) : 0,
                NewSalCoefficient13: dataForm && dataForm.NewSalCoefficient13 ? Number(dataForm.NewSalCoefficient13) : 0,
                NewSalCoefficient14: dataForm && dataForm.NewSalCoefficient14 ? Number(dataForm.NewSalCoefficient14) : 0,
                NewSalCoefficient15: dataForm && dataForm.NewSalCoefficient15 ? Number(dataForm.NewSalCoefficient15) : 0,
                NewSalCoefficient16: dataForm && dataForm.NewSalCoefficient16 ? Number(dataForm.NewSalCoefficient16) : 0,
                NewSalCoefficient17: dataForm && dataForm.NewSalCoefficient17 ? Number(dataForm.NewSalCoefficient17) : 0,
                NewSalCoefficient18: dataForm && dataForm.NewSalCoefficient18 ? Number(dataForm.NewSalCoefficient18) : 0,
                NewSalCoefficient19: dataForm && dataForm.NewSalCoefficient19 ? Number(dataForm.NewSalCoefficient19) : 0,
                NewSalCoefficient20: dataForm && dataForm.NewSalCoefficient20 ? Number(dataForm.NewSalCoefficient20) : 0,
                BaseSalary01: dataOldForm && dataOldForm.BaseSalary01 ? Number(dataOldForm.BaseSalary01) : 0,
                BaseSalary02: dataOldForm && dataOldForm.BaseSalary02 ? Number(dataOldForm.BaseSalary02) : 0,
                BaseSalary03: dataOldForm && dataOldForm.BaseSalary03 ? Number(dataOldForm.BaseSalary03) : 0,
                BaseSalary04: dataOldForm && dataOldForm.BaseSalary04 ? Number(dataOldForm.BaseSalary04) : 0,
                SalCoefficient01: dataOldForm && dataOldForm.SalCoefficient01 ? Number(dataOldForm.SalCoefficient01) : 0,
                SalCoefficient02: dataOldForm && dataOldForm.SalCoefficient02 ? Number(dataOldForm.SalCoefficient02) : 0,
                SalCoefficient03: dataOldForm && dataOldForm.SalCoefficient03 ? Number(dataOldForm.SalCoefficient03) : 0,
                SalCoefficient04: dataOldForm && dataOldForm.SalCoefficient04 ? Number(dataOldForm.SalCoefficient04) : 0,
                SalCoefficient05: dataOldForm && dataOldForm.SalCoefficient05 ? Number(dataOldForm.SalCoefficient05) : 0,
                SalCoefficient06: dataOldForm && dataOldForm.SalCoefficient06 ? Number(dataOldForm.SalCoefficient06) : 0,
                SalCoefficient07: dataOldForm && dataOldForm.SalCoefficient07 ? Number(dataOldForm.SalCoefficient07) : 0,
                SalCoefficient08: dataOldForm && dataOldForm.SalCoefficient08 ? Number(dataOldForm.SalCoefficient08) : 0,
                SalCoefficient09: dataOldForm && dataOldForm.SalCoefficient09 ? Number(dataOldForm.SalCoefficient09) : 0,
                SalCoefficient10: dataOldForm && dataOldForm.SalCoefficient10 ? Number(dataOldForm.SalCoefficient10) : 0,
                SalCoefficient11: dataOldForm && dataOldForm.SalCoefficient11 ? Number(dataOldForm.SalCoefficient11) : 0,
                SalCoefficient12: dataOldForm && dataOldForm.SalCoefficient12 ? Number(dataOldForm.SalCoefficient12) : 0,
                SalCoefficient13: dataOldForm && dataOldForm.SalCoefficient13 ? Number(dataOldForm.SalCoefficient13) : 0,
                SalCoefficient14: dataOldForm && dataOldForm.SalCoefficient14 ? Number(dataOldForm.SalCoefficient14) : 0,
                SalCoefficient15: dataOldForm && dataOldForm.SalCoefficient15 ? Number(dataOldForm.SalCoefficient15) : 0,
                SalCoefficient16: dataOldForm && dataOldForm.SalCoefficient16 ? Number(dataOldForm.SalCoefficient16) : 0,
                SalCoefficient17: dataOldForm && dataOldForm.SalCoefficient17 ? Number(dataOldForm.SalCoefficient17) : 0,
                SalCoefficient18: dataOldForm && dataOldForm.SalCoefficient18 ? Number(dataOldForm.SalCoefficient18) : 0,
                SalCoefficient19: dataOldForm && dataOldForm.SalCoefficient19 ? Number(dataOldForm.SalCoefficient19) : 0,
                SalCoefficient20: dataOldForm && dataOldForm.SalCoefficient20 ? Number(dataOldForm.SalCoefficient20) : 0,
                Mode: mode === "edit" ? 1 : 0,
                arrAttachment: JSON.stringify(arrAttachments)
            };
            if(mode === "edit") {
                params = JSON.parse(JSON.stringify(params, ['ProTransID','Mode','ValidDate', 'ReasonID', 'Notice','NewBaseSalary01','NewBaseSalary02','NewBaseSalary03','NewBaseSalary04',
                    'NewSalCoefficient02', 'NewSalCoefficient03', 'NewSalCoefficient04', 'NewSalCoefficient05', 'NewSalCoefficient06',
                    'NewSalCoefficient07', 'NewSalCoefficient08', 'NewSalCoefficient09', 'NewSalCoefficient10', 'NewSalCoefficient11', 'NewSalCoefficient12',
                    'NewSalCoefficient13', 'NewSalCoefficient14', 'NewSalCoefficient15', 'NewSalCoefficient16', 'NewSalCoefficient17',
                    'NewSalCoefficient18', 'NewSalCoefficient19', 'NewSalCoefficient20','arrAttachment']));
            }
            this.setState({loading: true});
            this.props.w09F2032Actions[mode](params, (error, data) => {
                this.setState({loading: false});
                if (error) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("ERROR", message);
                    return false;
                }
                if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu");
                        Config.popup.show('ERROR', message);
                        return false;
                    } else if (data.Status === 0) {
                        this._removeCDN();
                        this.props.onClose(true);
                        Config.notify.show('success', Config.lang("DHR_Luu_thanh_cong"), 2000);
                    } else {
                        Config.popup.show('ERROR', Config.lang("DHR_Da_co_loi_xay_ra_khong_the_luu_du_lieu"));
                        return false;
                    }
                }
            });
        }

    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    resetForm = () => {
        this.setState({
            dataOldForm: {
                BaseSalary01: 0,
                BaseSalary02: 0,
                BaseSalary03: 0,
                BaseSalary04: 0,
                SalCoefficient01: 0,
                SalCoefficient02: 0,
                SalCoefficient03: 0,
                SalCoefficient04: 0,
                SalCoefficient05: 0,
                SalCoefficient06: 0,
                SalCoefficient07: 0,
                SalCoefficient08: 0,
                SalCoefficient09: 0,
                SalCoefficient10: 0,
                SalCoefficient11: 0,
                SalCoefficient12: 0,
                SalCoefficient13: 0,
                SalCoefficient14: 0,
                SalCoefficient15: 0,
                SalCoefficient16: 0,
                SalCoefficient17: 0,
                SalCoefficient18: 0,
                SalCoefficient19: 0,
                SalCoefficient20: 0,
            },
            dataForm: {
                ProTransID: "",
                Employee: null,
                ValidDate: null,
                ReasonID: "",
                Notice: "",
                NewBaseSalary01: 0,
                NewBaseSalary02: 0,
                NewBaseSalary03: 0,
                NewBaseSalary04: 0,
                NewSalCoefficient01: 0,
                NewSalCoefficient02: 0,
                NewSalCoefficient03: 0,
                NewSalCoefficient04: 0,
                NewSalCoefficient05: 0,
                NewSalCoefficient06: 0,
                NewSalCoefficient07: 0,
                NewSalCoefficient08: 0,
                NewSalCoefficient09: 0,
                NewSalCoefficient10: 0,
                NewSalCoefficient11: 0,
                NewSalCoefficient12: 0,
                NewSalCoefficient13: 0,
                NewSalCoefficient14: 0,
                NewSalCoefficient15: 0,
                NewSalCoefficient16: 0,
                NewSalCoefficient17: 0,
                NewSalCoefficient18: 0,
                NewSalCoefficient19: 0,
                NewSalCoefficient20: 0,
            }
        });
    };

    onClose = () => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose();
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: data && data.ProTransID ? data.ProTransID : "",
            TableName: "D09T2060",
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({uploading: value});
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [ ...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2030",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const _d = data && data.rows ? data.rows : [];
                const total = data && data.total ? data.total : 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : dataCboEmployees.rows.concat(_d),
                        total: total,
                    },
                });
            }
        });
    };

    render() {
        let { classes, getCboReasons, mode, getCaptions, getCboCodes } = this.props;
        const {
            isExpanded,
            uploading,
            dataForm,
            dataOldForm,
            error,
            loading,
            dataOldAttachments,
            disabled,
            dataCboEmployees,
            employeeLoading,
        } = this.state;

        const employee = Config.getUser({EmployeeID: dataForm?.Employee?.EmployeeID});
        return (
            <>
                <Modal.Content>
                            <React.Fragment>
                                <Row>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Dropdown
                                            className={'mgt10 mgb0'}
                                            required
                                            error={error && error["EmployeeID"]}
                                            valueObjectDefault={
                                                typeof employee === "object" ? employee : null
                                            }
                                            dataSource={dataCboEmployees.rows}
                                            displayExpr={"EmployeeName"}
                                            subText={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            loading={employeeLoading}
                                            total={dataCboEmployees.total}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            renderSelectedItem={(data) => {
                                                return (
                                                    <div className={"display_row align-center"}>
                                                        <Avatar
                                                            readOnly
                                                            width={24}
                                                            height={24}
                                                            data={data?.data}
                                                            src={`${Config.getUserPicture(data?.data?.UserPictureURL)}`}
                                                        />
                                                        <Typography
                                                            hoverTooltip
                                                            className={"mgl5"}
                                                            lineClamp={1}
                                                        >{`${data?.data?.EmployeeID} - ${data?.data?.EmployeeName}`}</Typography>
                                                    </div>
                                                );
                                            }}
                                            value={dataForm?.Employee?.EmployeeID}
                                            disabled={mode === "edit" || loading || disabled}
                                            label={Config.lang("Nhan_vien")}
                                            inputProps={{
                                                readOnly: disabled,
                                            }}
                                            iconExpr={{
                                                key: "UserPictureURL",
                                                prefix: Config.getCDNPath(),
                                            }}
                                            onChange={(e) => this.handleChange("EmployeeID", e)}
                                            onInput={(e) => {
                                                this.filterCboEmployees.skip = 0;
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <DateBoxPicker
                                            label={Config.lang("DHR_Ngay_hieu_luc")}
                                            useMaskBehavior={true}
                                            showClearButton={true}
                                            required={true}
                                            width={"100%"}
                                            stylingMode={"underlined"}
                                            shrink={true}
                                            value={dataForm.ValidDate}
                                            onValueChanged={e => this.handleChange("ValidDate", e)}
                                            margin={"normal"}
                                            className={disabled ? classes.styleDisabled : ""}
                                            disabled={loading || disabled}
                                            error={error && error["ValidDate"]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    {
                                        getCboCodes && getCboCodes.map((data, index) => {
                                            if(data.Disabled) return null;
                                            let num = data.Code.slice(-2);
                                            const nameField = "NewBaseSalary" + num;
                                            const value = dataForm["NewBaseSalary" + num] ? dataForm["NewBaseSalary" + num] : Number(dataOldForm["BaseSalary" + num]);
                                            return(
                                                <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        label={data.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                                        thousandSeparator={true}
                                                        decimalScale={data.Decimals ? data.Decimals : 0}
                                                        value={String(value)}
                                                        disabled={loading || disabled}
                                                        onValueChange={e => this.handleChange(nameField,e)}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }

                                    {
                                        getCaptions && getCaptions.map((data, index) => {
                                            if(data.Disabled) return null;
                                            let num = data.Code.slice(-2);
                                            const nameField = "NewSalCoefficient" + num;
                                            const value = dataForm["NewSalCoefficient" + num] ? dataForm["NewSalCoefficient" + num] : Number(dataOldForm["SalCoefficient" + num]);
                                            return(
                                                <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        label={data.CaptionName + ' ' + Config.lang('DHR_Moi').toLowerCase()}
                                                        thousandSeparator={true}
                                                        decimalScale={data.Decimals ? data.Decimals : 0}
                                                        value={String(value)}
                                                        disabled={loading || disabled}
                                                        onValueChange={e => this.handleChange(nameField,e)}
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant={"standard"}
                                                        margin={"normal"}
                                                    />
                                                </Col>
                                            )
                                        })
                                    }

                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            dataSource={Config.storeDataSoureDevExtreme(getCboReasons)}
                                            displayExpr={"ReasonName"}
                                            valueExpr={"ReasonID"}
                                            stylingMode={"underlined"}
                                            disabled={loading || disabled}
                                            shrink={true}
                                            value={dataForm.ReasonID}
                                            label={Config.lang("DHR_Ly_do")}
                                            className={disabled ? classes.styleDisabled : ""}
                                            onValueChanged={e => this.handleChange("ReasonID", e)}
                                            margin={"normal"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            label={Config.lang("DHR_Ghi_chu")}
                                            variant={"standard"}
                                            value={dataForm.Notice}
                                            disabled={loading || disabled}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={e => this.handleChange("Notice", e)}
                                            fullWidth
                                        />
                                    </Col>
                                </Row>
                                <FormGroup className={"mgt10"}>
                                    <ExpansionPanel className={classes.expandPanel + " mgb10"}
                                                    onChange={(e, isExpaned) => {
                                                        this.setState({isExpanded: isExpaned})
                                                    }}>
                                        <ExpansionPanelSummary
                                            href={""}
                                            className={classes.expandSummary}
                                            aria-label="Expand"
                                        >
                                            <h5><b>{Config.lang("DHR_Thong_tin_cu")}</b></h5>&nbsp;
                                            {isExpanded ?
                                                <Image alt={""}
                                                       src={require('../../../../assets/images/icon-collapse.svg')}/>
                                                :
                                                <Image alt={""}
                                                       src={require('../../../../assets/images/icon-expand.svg')}/>
                                            }
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.expandDetail}>
                                            <Row>
                                                {
                                                    getCboCodes && getCboCodes.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let num = data.Code.slice(-2);
                                                        return(
                                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                                <span style={{fontWeight: 600}}>{data.CaptionName}:</span>&nbsp;
                                                                {dataOldForm["BaseSalary" + num] || dataOldForm["BaseSalary" + num] === 0 ?   new Intl.NumberFormat().format(Number(dataOldForm["BaseSalary" + num])) : ""}
                                                            </Col>
                                                        )
                                                    })
                                                }
                                                {
                                                    getCaptions && getCaptions.map((data, index) => {
                                                        if(data.Disabled) return null;
                                                        let num = data.Code.slice(-2);
                                                        return(
                                                            <Col key={index} xs={12} sm={12} md={6} lg={6}>
                                                                <span style={{fontWeight: 600}}>{data.CaptionName}:</span>&nbsp;
                                                                {dataOldForm["SalCoefficient" + num] || dataOldForm["SalCoefficient" + num] === 0 ?   new Intl.NumberFormat().format(Number(dataOldForm["SalCoefficient" + num])) : ""}
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </FormGroup>
                                <FormGroup style={{marginBottom: 34}}>
                                    <Attachments
                                        ref={ref => this.attRef = ref}
                                        showButton={true}
                                        files={dataOldAttachments}
                                        disabled={loading || uploading || disabled || mode === "view"}
                                        maxLength={5}
                                        uploading={loading}
                                        onUploading={this.onUploading}
                                        onChanged={this.onChangeAttachments}
                                    />
                                </FormGroup>
                            </React.Fragment>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <div>
                        <IconButton aria-label="view" disabled={loading || uploading || disabled || mode === "view"} onClick={this.onAttachment}>
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={loading || uploading || disabled || mode === "view"}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>

                </Modal.Actions>
            </>
        );
    }
}

W09F2032Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getCaptions: state.W09F2032.getCaptions,
            getCboCodes: state.W09F2032.getCboCodes,
            getCboReasons: state.W09F2032.getCboReasons,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID
        }),
        dispatch => ({
            w09F2032Actions: bindActionCreators(W09F2032Actions, dispatch),
            generalActions:  bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W09F2032Popup);
