/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/27/2020
 * @Example 
 */

import React from "react";
import { FormGroup, Col, Row, Image } from "react-bootstrap";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Approvals from "../../../approvals/approvals";
import LabelText from "../../../common/label-text/label-text";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Input, withStyles } from "@material-ui/core";
import InlineSVG from "react-inlinesvg";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2031Actions from "../../../../redux/W0X/W09F2031/W09F2031_actions";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import { Combo } from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import _ from "lodash";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        minHeight: 32,
        maxHeight: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        maxWidth: '100%',
        objectFit: 'contain'
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color: "#757575"
    },
    divText: {
        // fontSize: "1rem",
        fontWeight: 500,
        // color:"#575757"
    },
    expandPanel: {
        boxShadow: 'none'
    },
    expandSummary: {
        minHeight: '37px !important',
        padding: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0
        }
    },
    expandDetail: {
        display: 'block',
        padding: '10px 0'
    }
};

class W09F2031 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            AdSalOperator: 0, // Mặc định gọi form cũ
            isExpanded: false,
            VoucherID: "",
            loading: false,
            loadingCboDepartment: false,
            loadingCboEmployee: false,
            loadingCboStatus: false,
            loadingCboCaption: false,
            loadingCboCode: false,
            dataForm: {},
            dataCboEmployees: { rows: [], total: 0 },
            dataCboProjects: { rows: [], total: 0 },
            DateFrom: null,
            DateTo: null,
            Employee: "",
            DepartmentID: "",
            StatusID: ""
        };
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2031", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ['loading' + key]: status });

    };

    loadCboEmployees = (isReset) => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W09F2031",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setLoading("CboEmployee", true);
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setLoading("CboEmployee", false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboDepartment = () => {
        const params = {
            HostID: "",
            FormID: "W09F2031",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84"
        };
        this.setLoading("CboDepartment", true);
        this.props.generalActions.getCboDepartments(params, error => {
            this.setLoading("CboDepartment", false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadCboStatus = (oldForm = true) => {
        let actionRedirect = "approvalActions";
        let apiName = "getCboStatus";
        let params = {
            Language: Config.language || '84'
        };
        if (oldForm === false) { // Form mới 
            actionRedirect = "w09F2031Actions";
            apiName = "getCboStatusNew";
        } else {
            params["FormID"] = "STANDARD";
        }
        this.setLoading("CboStatus", true);
        this.props[actionRedirect][apiName](params, (error) => {
            this.setLoading("CboStatus", false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    loadCaption = (oldForm = true) => {
        let apiName = "getCaption";
        if (oldForm === false) { // Form mới 
            apiName = "getCaptionNew";
        }
        this.setLoading("Caption", true);
        this.props.w09F2031Actions[apiName](error => {
            this.setLoading("Caption", false);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };

    loadCboCode = (oldForm = true) => {
        let apiName = "getCboCode";
        if (oldForm === false) { // Form mới 
            apiName = "getCboCodeNew";
        }
        this.setLoading("CboCode", true);
        this.props.w09F2031Actions[apiName](error => {
            this.setLoading("CboCode", true);
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("ERROR", message);
                return false;
            }
        });
    };


    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return false;
        const { getCboDepartments, getCboEmployees } = this.props;
        this.loadCheckForm((mode) => {
            if (mode === 1) {
                this.loadNewForm();
            } else { // Mặc định load form cũ
                this.loadOldForm();
            }
        });
        // this.checkNotify();
        if (_.isEmpty(getCboDepartments)) this.loadCboDepartment();
        if (_.isEmpty(getCboEmployees)) this.loadCboEmployees();
    };

    loadOldForm = () => {
        const { getCboStatus, getCaption, getCboCode } = this.props;
        if (_.isEmpty(getCboStatus)) this.loadCboStatus();
        if (_.isEmpty(getCaption)) this.loadCaption();
        if (_.isEmpty(getCboCode)) this.loadCboCode();
    }

    loadNewForm = () => {
        const { getCboStatusNew, getCaptionNew, getCboCodeNew } = this.props;
        if (_.isEmpty(getCboStatusNew)) this.loadCboStatus(false);
        if (_.isEmpty(getCaptionNew)) this.loadCaption(false);
        if (_.isEmpty(getCboCodeNew)) this.loadCboCode(false);
    }

    // UNSAFE_componentWillReceiveProps = () => {
    //     this.checkNotify();
    // };
    //
    // checkNotify = () => {
    //     const {VoucherID} = this.getInfo();
    //     if(VoucherID && !this.voucher_id){
    //         this.voucher_id = VoucherID;
    //         this.loadFormInfo(VoucherID,(data)=>{
    //             this.voucher_id=null;
    //             data.VoucherID=VoucherID;
    //             this.refs['Approvals'].showPopup(data);
    //         });
    //     }
    // };
    //
    // getInfo = () => {
    //     const {location} = this.props;
    //     const url = new window.URLSearchParams(window.location.search);
    //     if (url && url.get('voucher_id')) {
    //         return {VoucherID: url.get('voucher_id')};
    //     } else if (location && location.state) {
    //         return {
    //             VoucherID: location.state.voucher_id,
    //         }
    //     } else {
    //         return {};
    //     }
    // };

    loadCheckForm = (cb) => {
        this.props.w09F2031Actions.loadCheckForm((error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && cb) {
                const AdSalOperator = data?.AdSalOperator || 0;
                if (AdSalOperator !== 0) this.setState({ AdSalOperator })
                cb(AdSalOperator);
            }
        });
    };

    handleFilter = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        this.setState({
            [key]: value
        });
    };

    onSelectionChanged = (e) => {
        if (!e) return false;
        const { AdSalOperator } = this.state;
        if (AdSalOperator === 1) {
            this.loadFormInfo(e.VoucherID, false);
        } else {
            this.loadFormInfo(e.VoucherID);
        }
    };

    onDrawerClosed = () => {
        this.setState({ VoucherID: "" });
    };

    loadFormInfo = (VoucherID = "", oldForm = true, cb) => {
        let actionRedirect = "approvalActions";
        let apiName = "getFormInfo";
        if (oldForm === false) { // Form mới 
            actionRedirect = "w09F2031Actions";
            apiName = "loadFormNew";
        }
        const params = {
            FormID: "W09F2031",
            DivisionID: Config.getDivisionID(),
            UserID: Config.profile.UserID,
            Language: Config.language || "84",
            VoucherID,
        };
        this.props[actionRedirect][apiName](params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({ dataForm: data });
                cb && cb(data);
            }
        });
    };

    renderFormInfo = (data) => {
        const { classes, getCaption, getCboCode, getCaptionNew, getCboCodeNew } = this.props;
        const { isExpanded, AdSalOperator } = this.state;

        let captions = getCaption;
        let cboCode = getCboCode;
        let capName = "NewSalCoefficient";
        let baseName = "NewBaseSalary";
        const user = Config.getUser({ EmployeeID: _.get(data, "EmployeeID", "") });
        const userPictureUrl = _.get(user, "UserPictureURL", "");
        const userName = _.get(user, "EmployeeName", "");
        if (AdSalOperator === 1) {
            captions = getCaptionNew;
            cboCode = getCboCodeNew;
            capName = "SalCoefficient";
            baseName = "BaseSalary";
        }

        return (
            <>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                className={classes.labelEmployee}
                                fullWidth={true}
                                label={Config.lang("DHR_Nhan_vien")}
                                allowPadding={true}
                                value={() => (
                                    <div className={"display_row align-center pdb5"} style={{ width: "100%", height: 37 }}>
                                        <div className={classes.divAvatar}>
                                            {userPictureUrl ? (
                                                <Image
                                                    className={classes.imgAvatar}
                                                    src={
                                                        userPictureUrl.indexOf("http") < 0
                                                            ? Config.getCDNPath() + userPictureUrl
                                                            : userPictureUrl
                                                    }
                                                />
                                            ) : (
                                                <InlineSVG
                                                    className={classes.imgAvatar}
                                                    src={require("../../../../assets/images/general/user_default.svg")}
                                                />
                                            )}
                                        </div>
                                        <Input color={"primary"} readOnly={true} value={userName ? userName : ""} disableUnderline={true} />
                                    </div>
                                )}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ngay_hieu_luc")}
                                fullWidth={true}
                                value={Config.convertDate(data.ValidDate, "", "DD/MM/YYYY")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup className={"mgb0"}>
                    <Row>
                        {cboCode && cboCode.length > 0 && cboCode.map((field) => {
                            let code = field && field.Code ? field.Code.slice(-2) : "";
                            let dataField = baseName + code;
                            if (field.Disabled !== 1) {
                                return (
                                    <Col key={field.Code} xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                        <LabelText
                                            label={field.CaptionName}
                                            value={data[dataField] === 0 ? "0" : data[dataField]}
                                            fullWidth={true}
                                        />
                                    </Col>
                                );
                            }
                            return null;
                        })}
                        {captions && captions.length > 0 && captions.map((field) => {
                            let code = field && field.Code ? field.Code.slice(-2) : "";
                            let dataField = capName + code;
                            if (field.Disabled !== 1) {
                                return (
                                    <Col key={field.Code} xs={12} sm={12} md={6} lg={6} className={"mgb15"}>
                                        <LabelText
                                            label={field.CaptionName}
                                            value={data[dataField] === 0 ? "0" : data[dataField]}
                                            fullWidth={true}
                                        />
                                    </Col>
                                );
                            }
                            return null;
                        })}
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ly_do")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "ReasonName", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <LabelText
                                allowPadding={true}
                                label={Config.lang("DHR_Ghi_chu")}
                                fullWidth={true}
                                value={Config.helpers.getObjectValue(data, "Notice", "")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                {AdSalOperator === 0 &&
                    <FormGroup className={"mgt10"}>
                        <ExpansionPanel className={classes.expandPanel + " mgb10"}
                            onChange={(e, isExpaned) => {
                                this.setState({ isExpanded: isExpaned })
                            }}>
                            <ExpansionPanelSummary
                                href={""}
                                className={classes.expandSummary}
                                aria-label="Expand"
                            >
                                <h5 style={{ margin: '5px 0' }}><b>{Config.lang("DHR_Thong_tin_cu")}</b></h5>&nbsp;
                                {isExpanded ?
                                    <Image alt={""}
                                        src={require('../../../../assets/images/icon-collapse.svg')} />
                                    :
                                    <Image alt={""}
                                        src={require('../../../../assets/images/icon-expand.svg')} />
                                }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expandDetail}>
                                <Row>
                                    {getCboCode && getCboCode.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "BaseSalary" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={12} md={6} lg={6}>
                                                <span style={{ fontWeight: 600 }}>{field.CaptionName || ""}:</span>&nbsp;
                                                {(data[dataField] && new Intl.NumberFormat().format(data[dataField])) || 0}
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Row>
                                    {getCaption && getCaption.map((field, indx) => {
                                        if (field.Disabled) return null;
                                        const no = field && field.Code ? field.Code.slice(-2) : "";
                                        const dataField = "SalCoefficient" + no;
                                        return (
                                            <Col key={indx} xs={12} sm={12} md={6} lg={6}>
                                                <span style={{ fontWeight: 600 }}>{field.CaptionName || ""}:</span>&nbsp;
                                                {(data[dataField] && new Intl.NumberFormat().format(data[dataField])) || 0}
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </FormGroup>
                }
            </>
        )
    };

    loadGrid = () => {
        if (this.refs['Approvals']) this.refs['Approvals'].onReset(true);
    };

    renderFilter = () => {
        const { getCboStatus, getCboDepartments } = this.props;
        const { dataCboEmployees, DateTo, DateFrom, loadingCboEmployee, StatusID, loadingCboDepartment, loadingCboStatus, Employee, DepartmentID } = this.state;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <div className={"display_row align-center"}>
                                        <Col xs={12} sm={2}> <span style={{ fontWeight: 700 }}>{Config.lang("DHR_Ngay")}</span></Col>
                                        <Col xs={12} sm={5}>
                                            <div className={"display_row align-center"}>
                                                <DateBoxPicker
                                                    max={DateTo}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    placeholder={Config.lang("DHR_Tu_ngay")}
                                                    value={DateFrom}
                                                    onValueChanged={(e) => this.handleFilter("DateFrom", e)}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            <div className={"display_row align-center"}>
                                                <DateBoxPicker
                                                    min={DateFrom}
                                                    placeholder={Config.lang("DHR_Den_ngay")}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    width={"100%"}
                                                    value={DateTo}
                                                    onValueChanged={(e) => this.handleFilter("DateTo", e)}
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            dataSource={getCboStatus}
                                            showClearButton={true}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            value={StatusID}
                                            loading={loadingCboStatus}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            onValueChanged={e => this.handleFilter("StatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={"EmployeeID"}
                                            valueExpr={"EmployeeID"}
                                            value={Employee}
                                            loading={loadingCboEmployee}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Nhan_vien")}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + " - " + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={(e) => this.handleFilter("Employee", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            dataSource={getCboDepartments}
                                            showClearButton={true}
                                            displayExpr={"DepartmentName"}
                                            valueExpr={"DepartmentID"}
                                            value={DepartmentID}
                                            loading={loadingCboDepartment}
                                            stylingMode={"outlined"}
                                            label={Config.lang("DHR_Phong_ban")}
                                            onValueChanged={(e) => this.handleFilter("DepartmentID", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={this.loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };


    render() {
        const { iPermission, dataForm, VoucherID, DateFrom, DateTo, Employee, DepartmentID, StatusID } = this.state;
        if (!iPermission) return null;
        const objSearch = {
            UserID: Config.profile.UserID,
            ApprovalStatus: StatusID || StatusID === 0 ? Number(StatusID) : "",
            DateFrom: DateFrom,
            DateTo: DateTo,
            EmployeeID: Employee ? Employee.EmployeeID : "",
            DepartmentID: DepartmentID,
            ProjectID: "",
            DateFrom2: null,
            DateTo2: null,
            TransTypeID: "",
            DutyID: "",
            IsRegisterType: 0,
        };

        return (
            <React.Fragment>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("DHR_Duyet_dieu_chinh_luong")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Approvals
                                allowHistoryApproval
                                parentProps={this.props}
                                FormID={"W09F2031"}
                                dataForm={dataForm}
                                ref={'Approvals'}
                                selectedRowKey={VoucherID}
                                disabledApproval={iPermission < 2}
                                singleClick
                                formInfoRender={this.renderFormInfo}
                                dataFilter={objSearch}
                                filterRender={() => this.renderFilter()}
                                onSelectionChanged={this.onSelectionChanged}
                                onDrawerClosed={this.onDrawerClosed}
                                disableEscapeKeyDown={false}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        );
    }

}

export default compose(connect((state) => ({
    getCboDepartments: state.general.getCboDepartments,
    getCboStatus: state.approvals.getCboStatus,
    getCboEmployees: state.general.getCboEmployees,
    getCaption: state.W09F2031.getCaption,
    getCboCode: state.W09F2031.getCboCode,
    getCboStatusNew: state.W09F2031.getCboStatusNew,
    getCaptionNew: state.W09F2031.getCaptionNew,
    getCboCodeNew: state.W09F2031.getCboCodeNew,
}),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
        w09F2031Actions: bindActionCreators(W09F2031Actions, dispatch),
    })
), withStyles(styles, { withTheme: true }))(W09F2031);
