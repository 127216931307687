import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DatePicker,
    TextInput,
    Dropdown,
    Label,
    ButtonIcon,
    Button,
    NumberInput
} from 'diginet-core-ui/components';
import {Col, Row, FormGroup} from "react-bootstrap";
import Config from "../../../../config";
import Attachments from "../../../common/attachments/attachments";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2021Actions from "../../../../redux/W0X/W09F2021/W09F2021_actions";
import moment from "moment";
import _ from "lodash";
import Api from "../../../../services/api";
import CDN from "../../../CDN";

class W09F2021 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataOldAttachments: [],
            uploading: false,
            isSaving: false,
            employeeLoading: false,
            disableCboEmployee: false,
            iPermissionW09F2022: 0,
            dataForm: {
                EmployeeID: "",
                AbsentDateFrom: null,
                AbsentDateTo: null,
                PlanChildDate: null,
                ExpectWorkDay: null,
                TotalMonth: 0,
                NoteU: "",
            },
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            errors: {},
            isMonthDayOffBirth: 0
        };
        this.attachments = [];
        this.deletedFile = [];
        this.defaultParams = {
            FormID: "W09F2021",
            Language: Config.language || "84",
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.isMSS =  !!_.toNumber(Config.getLocalStorage("MENUTYPEDHR"));
    }

    handleChange = (key, e) => {
        const {dataForm, isMonthDayOffBirth, errors} = this.state;
        let objResultDate, objErrors = {};
        const arrKeyDependents = ["AbsentDateFrom", "TotalMonth"];
        const arrKeyRequired = [...arrKeyDependents, "EmployeeID"];
        const isTotalMonth = !!_.indexOf(arrKeyDependents, key);
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        if (_.includes(arrKeyDependents, key)) {
            const valAbsDateFrom = !isTotalMonth ? value : _.get(dataForm, "AbsentDateFrom", "");
            const valTotalMonth = isTotalMonth ? value : _.get(dataForm, "TotalMonth", 0);
            if (valAbsDateFrom) {
                let resultAbsDateTo = null;
                let resultExpectWorkDay = null;
                if (valTotalMonth) {
                    resultAbsDateTo = moment(new Date(valAbsDateFrom)).add(valTotalMonth, !!isMonthDayOffBirth ? "month" : "day").subtract(1, "day").format("YYYY-MM-DD");
                    resultExpectWorkDay = moment(new Date(valAbsDateFrom)).add(valTotalMonth, !!isMonthDayOffBirth ? "month" : "day").format("YYYY-MM-DD");
                }
                objResultDate = {
                    PlanChildDate: valAbsDateFrom,
                    AbsentDateTo: resultAbsDateTo,
                    ExpectWorkDay: resultExpectWorkDay
                };
            } else {
                objResultDate = {PlanChildDate: null, AbsentDateTo: null, ExpectWorkDay: null};
            }
        }
        if (_.includes(arrKeyRequired, key)) {
            if (value) {
                objErrors = _.omit(errors, key);
            } else {
                objErrors = {...errors, [key]: Config.lang("Truong_nay_bat_buoc_nhap")}
            }
        }
        this.setState({dataForm: {...dataForm, [key]: value, ...objResultDate}, errors: objErrors});
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadPermission = async () => {
        await this.props.generalActions.getPermission(
            "W09F2022",
            (isPer) => {
                let user = null;
                let disableCboEmployee = false;
                if (isPer <= 2) {
                    disableCboEmployee = true;
                    user = Config.getUser({EmployeeID: Config.getHREmployeeID()});
                }
                this.setState({disableCboEmployee, dataForm: {...this.state.dataForm, employee: user}});
            },
            true,
        );
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            ...this.defaultParams,
            FormID: "W09F2020",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({employeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({employeeLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const _d = data && data.rows ? data.rows : [];
                const total = data && data.total ? data.total : 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : dataCboEmployees.rows.concat(_d),
                        total: total,
                    },
                });
            }
        });
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: _.get(data, "MaternityTransID", ""),
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({dataOldAttachments: data});
            }
            this.setState({
                loading: false,
            });
        });
    };

    loadFormData = () => {
        const {Language} = this.defaultParams;
        const {dataForm} = this.state;
        const MaternityTransID = _.get(this.props.data, "MaternityTransID", "");
        const params = {
            Language,
            DivisionID: Config.getDivisionID(),
            MaternityTransID,
        };
        this.props.w09f2021Actions.getForm(params, (err, data) => {
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
                return;
            }
            if (data) {
                let getData = _.get(data, "[0]", _.get(data, "rows[0]", {}));
                let getTotalMonth = _.get(getData, "TotalMonth", 0);
                const getDataIsMonthDayOffBirth = _.get(getData, "IsMonthDayOffBirth", false);
                const getDataIsMonth = _.get(getData, "IsMonth", false);
                if (_.isNumber(getDataIsMonthDayOffBirth) && _.isNumber(getDataIsMonth) && getDataIsMonthDayOffBirth !== getDataIsMonth) {
                    getTotalMonth = !!getData.IsMonthDayOffBirth ? _.round(_.divide(_.toNumber(getData.TotalMonth), 30)) : _.round(_.multiply(_.toNumber(getData.TotalMonth), 30))
                }
                this.setState({
                    dataForm: {
                        ...dataForm,
                        ..._.omit({
                            ...getData,
                            TotalMonth: getTotalMonth
                        }, ["IsMonthDayOffBirth", "IsMonth"]),
                    },
                });
            }
        });
    };

    loadTotalDay = isMonthDayOffBirth => {
        const { dataForm } = this.state;
        this.props.w09f2021Actions.getTotalDay((err, data) => {
            if (err) {
                return(Config.popup.show("ERROR", err));
            }
            if (data) {
                const totalMonthDefault = data.TotalDay;
                this.setState({ isMonthDayOffBirth, dataForm: { ...dataForm, TotalMonth: totalMonthDefault }});
            }
        });
    }

    loadIsMonthDayOffBirth = async () => {
        const { mode } = this.props;
        const { dataForm } = this.state;
        await this.props.w09f2021Actions.getIsMonthDayOffBirth((err, data) => {
            let totalMonthDefault = dataForm.TotalMonth;
            const isMonthDayOffBirth = _.get(data, "IsMonthDayOffBirth", 0);
            if (err) {
                Config.popup.show("ERROR", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
                return;
            }
            if (isMonthDayOffBirth === 1) {
                totalMonthDefault = 6;
                this.setState({ isMonthDayOffBirth, dataForm: { ...dataForm, TotalMonth: totalMonthDefault }});
                return;
            }
            if (mode === "add" && isMonthDayOffBirth === 0) {
                    this.loadTotalDay(isMonthDayOffBirth);
                    return;
            }
        });
    };



    onUploading = (value) => {
        this.setState({uploading: value});
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        this.attachments.length ? this.setState({hasAttachment: true}) : this.setState({hasAttachment: false});
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onStore = async () => {
        const {mode, data, onClose} = this.props;
        const {dataForm, errors, isMonthDayOffBirth} = this.state;
        let objFieldsRequired = _.pick(dataForm, ["EmployeeID", "TotalMonth", "AbsentDateFrom"]);
        let objErrors = {};
        let EmployeeID = dataForm?.EmployeeID ?? "";
        if(!this.isMSS) {
            objFieldsRequired = _.omit(objFieldsRequired, ["EmployeeID"]);
            EmployeeID = Config.getHREmployeeID() ?? "";
        }
        _.forEach(objFieldsRequired, (v, k) => {
            if (!v) {
                objErrors = {...errors, [k]: Config.lang("Truong_nay_bat_buoc_nhap")};
            } else if(_.includes(errors, k)){
                objErrors = _.omit(errors, k)
            }
        });
        this.setState({errors: objErrors});
        if (!!_.size(objErrors)) return false;
        const arrAttachments = this._getAttachments();
        const params = {
            EmployeeID,
            MaternityTransID: _.get(data, "MaternityTransID", ""),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            DivisionID: Config.getDivisionID(),
            TotalMonth: dataForm?.TotalMonth || null,
            AbsentDateFrom: dataForm?.AbsentDateFrom || null,
            AbsentDateTo: dataForm?.AbsentDateTo || null,
            ExpectWorkDay: dataForm?.ExpectWorkDay || null,
            PlanChildDate: dataForm?.PlanChildDate || null,
            NoteU: dataForm?.NoteU || "",
            IsMonth: _.toNumber(isMonthDayOffBirth),
            arrAttachment: JSON.stringify(arrAttachments),
        };
        this.setState({isSaving: true});
        const res = await Api.put(`/api/w09f2021/${mode}`, params);
        this.setState({isSaving: false});
        const messageErr = _.get(res, "message", "");
        const resData = _.get(res, "data", null);
        const status = !_.get(resData, "Status", 1);
        const messageData = _.get(resData, "Message", "");
        if (messageErr) {
            Config.popup.show("ERROR", messageErr);
            return false
        }
        if (status) {
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            this._removeCDN();
            onClose("showW09F2021", true);
            return true;
        }
        Config.popup.show("ERROR", messageData);
        return false
    };

    convertDate = (date) => {
        if (!moment(date).isValid() || !date) return "";
        return moment.utc(date).format("DD/MM/YYYY");
    };

    getChildPolicyName = (childPolicyID) => {
        switch (childPolicyID) {
            case "LATE":
                return Config.lang("Di_tre");
            case "EARLY":
                return Config.lang("Ve_som");
            case "OUTEARLY":
                return Config.lang("Ra_som");
            case "INLATE":
                return Config.lang("Vao_tre");
            case "LEAVE":
                return Config.lang("Quy_doi_phep");
            default:
                break;
        }
    };

    render() {
        const {getForm, data, mode, onClose, open} = this.props;
        const {
            dataForm,
            dataOldAttachments,
            uploading,
            isSaving,
            employeeLoading,
            dataCboEmployees,
            disableCboEmployee,
            isMonthDayOffBirth,
            errors
        } = this.state;
        const dataAfterMaternity = mode === "edit" ? _.get(getForm, "[0]", "") : {};
        const appStatusID = _.get(data, "AppStatusID", _.get(getForm, "AppStatusID", 1));
        const isRegisterType = _.get(data, "IsRegisterType", _.get(getForm, "IsRegisterType", 0));
        const visibleGroup = dataAfterMaternity["ActualWorkDay"] && mode === "edit";
        const allowSave = (mode === "add" || (mode === "edit" && Number(appStatusID) === 0)) && !_.size(errors) && isRegisterType === 0;
        const getEmployeeID = this.isMSS ? _.get(dataForm,"EmployeeID", "") : Config.getHREmployeeID();
        const valueObjectDefault = Config.getUser({EmployeeID: getEmployeeID});
        return (
            <Modal open={open} onClose={() => onClose("showW09F2021")}>
                <ModalHeader title={Config.lang("Dang_ky_thai_san")}/>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <Dropdown
                                    error={errors?.EmployeeID}
                                    required
                                    valueObjectDefault={_.pick(valueObjectDefault, ["EmployeeID", "EmployeeName", "UserPictureURL"])}
                                    disabled={disableCboEmployee || isSaving }
                                    allowSearch
                                    readOnly={!this.isMSS}
                                    dataSource={dataCboEmployees.rows}
                                    total={dataCboEmployees.total}
                                    skip={this.filterCboEmployees.skip}
                                    limit={this.filterCboEmployees.limit}
                                    displayExpr={'{EmployeeID} - {EmployeeName}'}
                                    valueExpr={'EmployeeID'}
                                    value={getEmployeeID}
                                    loading={employeeLoading}
                                    clearAble
                                    label={Config.lang('Nhan_vien')}
                                    placeholder={Config.lang('Chon')}
                                    iconExpr={{key: 'UserPictureURL', prefix: Config.getCDNPath()}}
                                    onChange={e => this.handleChange("EmployeeID", e)}
                                    onInput={(e) => {
                                        const value = e.target.value;
                                        if (this.timer) clearTimeout(this.timer);
                                        this.timer = setTimeout(() => {
                                            this.filterCboEmployees.strSearch = value;
                                            this.filterCboEmployees.skip = 0;
                                            this.loadCboEmployees(true);
                                        }, 500);

                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCboEmployees.skip = e.skip;
                                        this.filterCboEmployees.limit = e.limit;
                                        this.loadCboEmployees();
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <Label>{Config.lang("Thoi_gian")}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <DatePicker
                                    error={errors?.AbsentDateFrom}
                                    displayFormat={"DD/MM/YYYY"}
                                    clearAble
                                    required
                                    disabled={isSaving}
                                    actionIconAt="start"
                                    label={Config.lang("Tu")}
                                    max={dataForm.AbsentDateTo}
                                    onChange={(e) => this.handleChange("AbsentDateFrom", e)}
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    value={dataForm.AbsentDateFrom}
                                />
                            </Col>
                            <Col md={4} xs={12}>
                                <DatePicker
                                    displayFormat={"DD/MM/YYYY"}
                                    actionIconAt="start"
                                    readOnly
                                    disabled={isSaving}
                                    label={Config.lang("Den")}
                                    min={dataForm.AbsentDateFrom}
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    value={dataForm.AbsentDateTo}
                                />
                            </Col>
                            <Col md={2} xs={12}>
                                <NumberInput
                                    error={errors?.TotalMonth}
                                    required
                                    label={!!isMonthDayOffBirth ? Config.lang("So_thang") : Config.lang("So_ngay")}
                                    disabled={isSaving}
                                    value={dataForm.TotalMonth}
                                    onChange={(e) => this.handleChange("TotalMonth", e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <DatePicker
                                    displayFormat={"DD/MM/YYYY"}
                                    actionIconAt="start"
                                    clearAble
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    label={Config.lang("Ngay_du_sinh")}
                                    value={dataForm.PlanChildDate}
                                    onChange={(e) => this.handleChange("PlanChildDate", e)}
                                    disabled={isSaving}
                                />
                            </Col>
                            <Col md={6}>
                                <DatePicker
                                    displayFormat={"DD/MM/YYYY"}
                                    actionIconAt="start"
                                    clearAble
                                    placeholder={Config.lang("DD/MM/YYYY")}
                                    label={Config.lang("Ngay_di_lam_du_kien")}
                                    value={dataForm.ExpectWorkDay}
                                    onChange={(e) => this.handleChange("ExpectWorkDay", e)}
                                    disabled={isSaving}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: 34}}>
                        <Row>
                            <Col md={12}>
                                <TextInput
                                    multiline
                                    label={Config.lang("Ghi_chu")}
                                    value={dataForm.NoteU}
                                    disabled={isSaving}
                                    onChange={(e) => this.handleChange("NoteU", e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {!!visibleGroup && (
                        <FormGroup style={{marginBottom: 34}}>
                            <FormGroup>
                                <Row>
                                    <Col md={12}>
                                        <Label>
                                            {Config.lang("Sau_thai_san:")}
                                        </Label>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Label>{Config.lang("Ngay_di_lam_thuc_te")}</Label>
                                        <Label style={{fontWeight: "bold"}}>
                                            {this.convertDate(dataAfterMaternity["ActualWorkDay"]) || ""}
                                        </Label>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Label>{Config.lang("Ngay_sinh_con")}</Label>
                                        <Label style={{fontWeight: "bold"}}>
                                            {this.convertDate(dataAfterMaternity["ChildBirthDate"]) || ""}
                                        </Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Label>{Config.lang("Che_do_con_nho")}</Label>
                                        <Label style={{fontWeight: "bold"}}>
                                            {this.getChildPolicyName(dataAfterMaternity["GetChildPolicyType"]) || ""}
                                        </Label>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Label>{Config.lang("Ngay_ket_thuc_che_do")}</Label>
                                        <Label style={{fontWeight: "bold"}}>
                                            {this.convertDate(dataAfterMaternity["MaternityEndDate"]) || ""}
                                        </Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Label>{Config.lang("Ghi_chu_sau_thai_san")}</Label>
                                        <Label>{dataAfterMaternity["NoteAfterOff"] || ""}</Label>
                                    </div>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                    <FormGroup style={{marginBottom: 34}}>
                        <Attachments
                            isAttachInfo
                            ref={(ref) => (this.attRef = ref)}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={mode === "view" || isSaving}
                            maxLength={5}
                            uploading={uploading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <div style={{width: "100%",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <ButtonIcon name="attachment" viewType="ghost" disabled={false} onClick={this.onAttachment}/>
                        <Button
                            loading={isSaving}
                            text={Config.lang("Luu")}
                            color={"primary"}
                            disabled={!allowSave || isSaving}
                            startIcon={"save"}
                            viewType={"filled"}
                            size={"medium"}
                            onClick={this.onStore}
                        />
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    componentDidMount = async () => {
        const {mode} = this.props;
        await this.loadPermission();
        await this.loadIsMonthDayOffBirth();
        if(this.isMSS) this.loadCboEmployees();
        setTimeout(()=>{
            if (mode !== "add") {
                this.loadFormData();
                this.loadAttachments();
            }
        },100)
    };
}

export default connect(
    (state) => ({
        getCboEmployees: state.general.getCboEmployees,
        getForm: state.W09F2021.getForm,
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w09f2021Actions: bindActionCreators(W09F2021Actions, dispatch),
    }),
)(W09F2021);
