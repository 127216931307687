/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import React from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import {browserHistory} from "react-router";
import {Column} from "devextreme-react/data-grid";
import {
    ButtonIcon,
    Button, 
    Avatar,
    Status,
    Icon,
    Typography,
    Modal,
    ModalHeader,
    Row,
    Col,
    Dropdown,
    DatePicker,
    Label,
} from "diginet-core-ui/components";

import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import Filter from "../../../filter/filter";
import W09F2010Popup from "../W09F2011/W09F2011";
import * as W09F2010Actions from "../../../../redux/W0X/W09F2010/W09F2010_actions";
import W84F3005 from '../../../W8X/W84/W84F3005/W84F3005';
class W09F2010 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            type: "ESS",
            rowData: null,
            showW09F2010Popup: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            loading: {
                cboEmployees: false,
                cboProjects: false,
            },
            showW84F3005: false,
            dataFilter: {
                DateFrom:null,
                DateTo:null,
                EmployeeID: null,
                ProjectID: null,
                AppStatusID:"",
            }
        };
        this.loading = {
            cboEmployees: false,
            cboProjects: false,
        };
        this.filter = {
            // DateFrom: "",
            // DateTo: "",
            // Employee: null,
            // Project: null,
            // AppStatusID: "",
            skip: 0,
            limit: 10
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboProjects = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W09F2010", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    renderEmpProfile = e => {
        // const {classes} = this.props;
        const {data} = e.row;
        const date = moment.utc(data.CreateDate).format("LT, DD/MM/YYYY");

        if (data) {
            if (data.UserPictureURL) {
                data.UserPictureURL = data.UserPictureURL.indexOf('http') < 0 ? Config.getCDNPath() + data.UserPictureURL : data.UserPictureURL;
            } else if (data.EmployeeID) {
                const user = Config.getUser({EmployeeID: data.EmployeeID});
                data.UserPictureURL = user && user.UserPictureURL ? user.UserPictureURL : ""
            }
        }
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div
                    className={"w75f2000_avatar "}
                    onClick={() => this.onChangePageProfile(data)}
                >
                    <Avatar
                        src={Config.getUserPicture(data.UserPictureURL)}
                        readOnly
                        hoverAble={false}
                        width={32}
                        height={32}
                        className={"mgr4x"}
                    />

                </div>
                
                <div className={"w75f2000_info"}>
                    <Typography type="h3">
                        {data.EmployeeName || ""}
                    </Typography>
                    <Typography type="p2">
                        {data.DepartmentName || ""}
                    </Typography>
                    <Status icon={<Icon name="Calendar" width={14} height={16} />}  text={data.CreateDate ? date : '---'} />
                </div>
            </div>
        );
    };

    renderInfo = e => {
        const {data} = e.row;
        //const date = moment(data.ApprovalDate).format("DD/MM/YYYY");
        // const {classes} = this.props;
        return (
            <div className={"pdt10 pdb10"}>
                <Typography>
                    {Config.lang("Ngay_bat_dau_nghi_viec")}:{" "}
                    {data.DateLeft ? moment.utc(data.DateLeft).format('DD/MM/YYYY') : ""}
                </Typography>
                <Typography>
                    {Config.lang("Phong_ban")}:{" "}
                    {data.DepartmentName || ""}
                </Typography>
                <Typography>
                    {Config.lang("Du_an")}:{" "}
                    {data.ProjectName || ""}
                </Typography>
                {data.ProposerName && (
                    <Typography>
                        {Config.lang("Nguoi_lap")}: {data.ProposerName || ""}
                    </Typography>
                )}
                {data.ApproverName && (
                    <Typography>
                        {Config.lang("Nguoi_duyet")}: {data.ApproverName || ""} -{" "}
                        {data.DepartmentID || ""} - {data.ApprovalDate || ""}
                    </Typography>
                )}
            </div>
        );
    };

    renderStatus = e => {
        const {data} = e.row;
        return <Status className="valign-middle" icon="HourGlass" color="info" text={data?.AppStatusName} />;
    };

    onAdd = () => {
        const {Type} = this.getInfo();
        this.setState({
            mode: "add",
            type: Type,
            showW09F2010Popup: true
        });
    };

    onView = e => {
        const {data} = e.row;
        const {Type} = this.getInfo();
        this.setState({
            mode: "view",
            type: Type,
            rowData: data,
            showW09F2010Popup: true
        });
    };

    onDelete = data => {
        const {LeaveTransID} = data;

        const param = {
            LeaveTransID
        };

        Config.popup.show(
            "YES_NO",
            Config.lang("Ban_co_chac_muon_xoa?"),
            () => {
                this.props.w09f2010Actions.deleteDataGrid(param, error => {
                    if (error) {
                        Config.popup.show("INFO", Config.lang("Loi_chua_xac_dinh"));
                        return false;
                    } else {
                        Config.notify.show(
                            "success",
                            Config.lang("Xoa_thanh_cong"),
                            3000
                        );
                        this.loadDataGrid();
                    }
                });
            }
        );
    };

    onViewHistory = (e) => {
        this.setState({ showW84F3005: true, rowData: e.data });
    };

    renderAction = e => {
        const {data} = e.row;
        const {iPermission} = this.state;
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                    circular
                    viewType={'text'}
                    name={'History'}
                    onClick={() => this.onViewHistory(e)}
                    className={'mgr5'}
                />
                <ButtonIcon
                  circular
                  viewType={"text"}
                  name={"View"}
                  className={'mgr5'}
                  disabled={Number(data?.OnlyWatch) === 1 ? false : iPermission<=0}
                  onClick={() => this.onView(e)}
                />
                <ButtonIcon
                  circular
                  viewType={"text"}
                  name={"Delete"}
                  disabled={Number(data?.OnlyWatch) === 1 || Number(data?.AppStatusID) !== 0 || iPermission<=3 }
                  onClick={() => this.onDelete(data)}
                />
            </div>
        );
    };

    filterChange = (key, data) => {
        const {value} = data;
        if (!key) return false;
        switch (key) {
            case "DateFrom":
            case "DateTo":
                this.setDataFilter(key, value ?? null);
                break;
            case "EmployeeID":
            case "ProjectID":
                this.setDataFilter(key, value ?? null);
                break;
            case "AppStatusID":
                this.setDataFilter(key, value ?? "");
                break;
            default:
                break;
        }
    };
    setDataFilter = (key, value) => {
        this.setState({
            dataFilter: {
                ...this.state.dataFilter,
                [key]: value
            }
        })
    };

    renderFilter = () => { 
        const {getCboAppStatus} = this.props;
        const {DateTo, DateFrom, ProjectID, AppStatusID, EmployeeID} = this.state?.dataFilter;
        const {dataCboEmployees, dataCboProjects, loading} = this.state;
        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                isUseDDCore={true}
                renderFilter={() => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Label style={{wordBreak: "inherit"}} type="p2">
                                            {Config.lang("Tu")}
                                        </Label>
                                        <DatePicker
                                            className="mgl1x"
                                            max={DateTo}
                                            onChange={e => this.filterChange("DateFrom", e)}
                                            clearAble
                                            placeholder={"DD/MM/YYYY"}
                                            displayFormat="DD/MM/YYYY"
                                            viewType="outlined"
                                            value={DateFrom}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <Label style={{wordBreak: "inherit"}} type="p2">
                                            {Config.lang("Den").toLowerCase()}
                                        </Label>
                                        <DatePicker
                                            className="mgl1x"
                                            min={DateFrom}
                                            onChange={e => this.filterChange("DateTo", e)}
                                            clearAble
                                            placeholder={"DD/MM/YYYY"}
                                            displayFormat="DD/MM/YYYY"
                                            viewType="outlined"
                                            value={DateTo && DateTo}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        label={Config.lang("Trang_thai_duyet")}
                                        dataSource={getCboAppStatus}
                                        displayExpr={"AppStatusName"}
                                        valueExpr={"AppStatusID"}
                                        onChange={e => this.filterChange("AppStatusID", e)}
                                        clearAble
                                        closeAfterSelect
                                        viewType="outlined"
                                        value={AppStatusID}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        dataSource={dataCboEmployees.rows}
                                        skip={this.filterCboEmployees.skip}
                                        limit={this.filterCboEmployees.limit}
                                        total={dataCboEmployees.total}
                                        displayExpr={"{EmployeeID}-{EmployeeName}"}
                                        keyExpr={'EmployeeID'}
                                        valueExpr={'EmployeeID'}
                                        value={EmployeeID ? EmployeeID : null}
                                        loading={loading.cboEmployees}
                                        viewType={'outlined'}
                                        label={Config.lang('Nhan_vien')}
                                        clearAble
                                        closeAfterSelect
                                        onChange={e => this.filterChange("EmployeeID", e)}
                                        onInput={(e) => {
                                            this.filterCboEmployees.strSearch = e.target.value;
                                            this.filterCboEmployees.skip = 0;
                                            this.loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboEmployees.skip = e.skip;
                                            this.filterCboEmployees.limit = e.limit;
                                            this.loadCboEmployees();
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Dropdown
                                        dataSource={dataCboProjects.rows}
                                        skip={this.filterCboProjects.skip}
                                        limit={this.filterCboProjects.limit}
                                        total={dataCboProjects.total}
                                        displayExpr={"{ProjectID}-{ProjectName}"}
                                        keyExpr={'ProjectID'}
                                        valueExpr={'ProjectID'}
                                        value={ProjectID ? ProjectID : null}
                                        loading={loading.cboProjects}
                                        viewType={'outlined'}
                                        label={Config.lang('Du_an')}
                                        clearAble
                                        closeAfterSelect
                                        onChange={e => this.filterChange("ProjectID", e)}
                                        onInput={(e) => {
                                            this.filterCboProjects.strSearch = e.target.value;
                                            this.filterCboProjects.skip = 0;
                                            this.loadCboProjects(true);
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboProjects.skip = e.skip;
                                            this.filterCboProjects.limit = e.limit;
                                            this.loadCboProjects();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <div className="display_row valign-middle">
                                <Button
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"search"}
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    componentDidMount = async() => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadDataGrid();
        this.loadCboAppStatus();

    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getDataGrid} = this.props;
        Config.callChildForm({
            FormID: "W09F2010",
            // ID: voucher_id,
            keyExpr: "LeaveTransID",
            data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onView({ row: { data: data } })
        }, this.props);
    }

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2010",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.loading.cboEmployees = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W09F2010",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.loading.cboProjects = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboProjects(param, (error, data) => {
            this.loading.cboProjects = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const {DateFrom, DateTo, EmployeeID, ProjectID, AppStatusID} = this.state.dataFilter;
        const { skip, limit } = this.filter; 
        const {Type} = this.getInfo();
        const {location} = this.props;
        const param = {
            TransID: VoucherID || "",
            FormID: "W09F2010",
            Language: Config.language || 84,
            DateFrom: DateFrom,
            DateTo: DateTo,
            EmployeeID: EmployeeID ?? "",
            ProjectID: ProjectID ?? "",
            AppStatusID: AppStatusID,
            Type: Type,
            skip: skip,
            limit: limit,
            IsMSS: +(location?.state?.menu?.Type === "MSS")
        };
        this.setState({gridLoading: true});
        this.props.w09f2010Actions.loadDataGrid(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({gridLoading: false});
        });
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W09F2010",
            Language: Config.language || 84
        };

        this.props.w09f2010Actions.loadAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    getInfo = () => {
        const { location } = this.props;
        let Type = 0;
        if (location && location.state) {
            Type = location.state.Type ? Number(location.state.Type) : 0;
        }
      return {
            Type: Type,
        };
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onCloseModal = (isSaved = false) => {
        if(isSaved) this.loadDataGrid();
        this.setState({
            showW09F2010Popup: false
        });
    };

    render() {
        const {getDataGrid} = this.props;
        const { iPermission, gridLoading, showW09F2010Popup, mode, type, rowData, showW84F3005 } = this.state;
        if (!iPermission) return null;
        const {skip, limit} = this.filter;
        return (
            <>
                {showW84F3005 && <W84F3005 open={showW84F3005} onClose={() => this.setState({ showW84F3005: false })} FormID="W09F2010" TransID={rowData?.LeaveTransID ?? ''} />}
                <Modal
                    open={showW09F2010Popup}
                    onClose={() => this.onCloseModal(false)}
                    pressESCToClose	
                >
                    <ModalHeader>
                        {Config.lang("Dang_ky_nghi_viec")}
                    </ModalHeader>
                    <W09F2010Popup
                        mode={mode}
                        type={type}
                        data={rowData}
                        onClose={this.onCloseModal}
                    />
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Nghi_viec")}>
                    <Button
                        label={Config.lang("Them")}
                        style={{textTransform: "uppercase"}}
                        color="primary"
                        onClick={this.onAdd}
                        size={"medium"}
                        viewType={"filled"}
                        startIcon={"AddFilled"}
                        disabled={iPermission<=1}
                    />
                </ActionToolbar>
                <GridContainer
                    style={{border: "none"}}
                    dataSource={getDataGrid && getDataGrid.rows}
                    showRowLines={true}
                    showBorders={false}
                    keyExpr={'LeaveTransID'}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("No_data")}
                    onCellClick={this.onDetail}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    totalItems={getDataGrid && Number(getDataGrid.total)}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    height={Config.getHeightGrid()}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile} width={250}/>
                    <Column cellRender={this.renderInfo}/>
                    <Column cellRender={this.renderStatus} alignment={"center"} width={200}/>
                    <Column cellRender={this.renderAction} width={200}/>
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.W09F2010.getCboAppStatus,
            getDataGrid: state.W09F2010.getDataGrid
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2010Actions: bindActionCreators(W09F2010Actions, dispatch)
        })
    )
)(W09F2010);
