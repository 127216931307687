import React, { Component } from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'diginet-core-ui/components';
import { Col, Row, FormGroup } from "react-bootstrap";
import Config from "../../../../config";
import DateBoxPicker from "../../../common/form-material/date-box";
import Attachments from "../../../common/attachments/attachments";
import { Combo } from "../../../common/form-material";
import ButtonGeneral from "../../../common/button/button-general";
import { withStyles, IconButton, TextField, Typography } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import moment from "moment";
import _ from "lodash";
import Api from "../../../../services/api";
import CDN from "../../../CDN";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W09F2021Actions from "../../../../redux/W0X/W09F2021/W09F2021_actions";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";

const styles = {
    asterisk: {
        "& .form-group": {
            "& .MuiPaper-root": {
                "& > :first-child": {
                    "& > h6::after": {
                        content: "'*'",
                        color: "red",
                        paddingLeft: 3,
                    },
                },
            },
        },
    },
    removeUnderLine: {
        "& .MuiInput-underline:before": {
            content: "none",
        },
        "& .MuiInput-underline:after": {
            content: "none",
        },
        "& .MuiInputBase-input": {
            cursor: "default",
        },
    },
};

class W09F2022 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataOldAttachments: [],
            uploading: false,
            loading: false,
            isSaveSuccess: false,
            hasAttachment: false,
            dataForm: {
                ActualWorkDay: "",
                GetChildPolicyMode: "",
                ChildBirthDate: "",
                MaternityEndDate: "",
                NoteAfterOff: "",
            },
            cbOChildPolicyMode: [
                {
                    GetChildPolicyType: "LATE",
                    childPolicyModeName: Config.lang("DHR_Di_tre"),
                },
                {
                    GetChildPolicyType: "EARLY",
                    childPolicyModeName: Config.lang("DHR_Ve_som"),
                },
                {
                    GetChildPolicyType: "OUTEARLY",
                    childPolicyModeName: Config.lang("DHR_Ra_som"),
                },
                {
                    GetChildPolicyType: "INLATE",
                    childPolicyModeName: Config.lang("DHR_Vao_tre"),
                },
                {
                    GetChildPolicyType: "LEAVE",
                    childPolicyModeName: Config.lang("DHR_Quy_doi_phep"),
                },
            ],
        };
        this.attachments = [];
        this.deletedFile = [];
        this.defaultParams = {
            FormID: "W09F2022",
            Language: Config.language || "84",
        };
    }

    handleChange = (key, e) => {
        const { dataForm } = this.state;
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        let updateMaternityEndDate = {};
        if (key === "ChildBirthDate") {
            updateMaternityEndDate = value && {
                MaternityEndDate: moment(new Date(value)).add(12, "month").subtract(1, "days").format("YYYY-MM-DD"),
            };
        }
        this.setState({
            dataForm: {
                ...dataForm,
                [key]: value,
                ...updateMaternityEndDate,
            },
        });
    };

    onStore = async () => {
        const { dataForm } = this.state;
        const { onClose } = this.props;
        const MaternityTransID = _.get(this.props, "data.MaternityTransID", "");
        const arrAttachments = this._getAttachments();
        const params = {
            DivisionID: Config.getDivisionID(),
            ...this.defaultParams,
            MaternityTransID,
            ...dataForm,
            arrAttachment: JSON.stringify(arrAttachments),
        };
        this.setState({ loading: true });
        const res = await Api.put("/w09f2022/add", params);
        this.setState({ loading: false });
        const messageErr = _.get(res, "message", "");
        const status = !_.get(res, "data.Status", 0);
        if (messageErr) {
            Config.popup.show("INFO", messageErr);
            return;
        }
        if (status) {
            Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
            this._removeCDN();
            onClose("showW09F2022", true);
            return;
        }
        Config.popup.show("INFO", _.get(res, "data.Message", Config.lang("DHR_Loi_chua_xac_dinh")));
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: _.get(data, "MaternityTransID", ""),
        };
        this.setState({ loading: true });
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    hasAttachment: true,
                    dataOldAttachments: data,
                });
            }
        });
    };

    loadFormData = () => {
        const { dataForm } = this.state;
        const MaternityTransID = _.get(this.props.data, "MaternityTransID", "");
        const params = {
            Language: Config.language || "84",
            DivisionID: Config.getDivisionID(),
            MaternityTransID,
        };
        this.setState({ loading: true });
        this.props.w09f2021Actions.getForm(params, (err, data) => {
            this.setState({ loading: false });
            if (err) {
                Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("DHR_Loi_chua_xac_dinh"));
                return;
            }
            if (data) {
                const resData = _.get(data, "[0]", _.get(data, "rows[0]", {}));
                this.setState({
                    dataForm: {
                        ...dataForm,
                        ..._.omit(resData, "GetChildPolicyType"),
                        GetChildPolicyMode: _.get(resData, "GetChildPolicyType", ""),
                    },
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        this.attachments.length || this.state.dataOldAttachments.length
            ? this.setState({ hasAttachment: true })
            : this.setState({ hasAttachment: false });
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    render() {
        const { classes, open, onClose } = this.props;
        const { cbOChildPolicyMode, dataForm, dataOldAttachments, uploading, hasAttachment, isSaveSuccess, loading } = this.state;
        const allowSave = dataForm.ActualWorkDay && dataForm.ChildBirthDate && dataForm.GetChildPolicyMode && dataForm.MaternityEndDate;
        return (
            <Modal open={open} onClose={() => onClose("showW09F2022")}>
                <ModalHeader title={Config.lang("DHR_Cap_nhat_sau_thai_san")} />
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <DateBoxPicker
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Ngay_di_lam_thuc_te")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.ActualWorkDay}
                                    onValueChanged={(e) => this.handleChange("ActualWorkDay", e)}
                                    margin={"normal"}
                                    disabled={loading}
                                    required={true}
                                />
                            </Col>
                            <Col md={4}>
                                <Combo
                                    dataSource={cbOChildPolicyMode}
                                    displayExpr={"childPolicyModeName"}
                                    valueExpr={"GetChildPolicyType"}
                                    subTextExpr={"GetChildPolicyType"}
                                    stylingMode={"underlined"}
                                    disabled={loading}
                                    searchEnabled={true}
                                    shrink={true}
                                    required={true}
                                    value={dataForm.GetChildPolicyMode}
                                    label={Config.lang("DHR_Che_do_sinh_con_nho")}
                                    onValueChanged={(e) => this.handleChange("GetChildPolicyMode", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            <Col md={2}>
                                <Typography style={{ paddingTop: "25%" }} variant="subtitle1">
                                    {`(60 ${Config.lang("DHR_Phut")})`}
                                </Typography>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <DateBoxPicker
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Ngay_sinh_con")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.ChildBirthDate}
                                    onValueChanged={(e) => this.handleChange("ChildBirthDate", e)}
                                    margin={"normal"}
                                    disabled={loading}
                                    required={true}
                                />
                            </Col>
                            <Col md={6}>
                                <DateBoxPicker
                                    height={28}
                                    useMaskBehavior={true}
                                    label={Config.lang("DHR_Ngay_ket_thuc_che_do")}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    InputLabelProps={{ style: { marginTop: "-7px" } }}
                                    value={dataForm.MaternityEndDate}
                                    onValueChanged={(e) => this.handleChange("MaternityEndDate", e)}
                                    margin={"normal"}
                                    disabled={loading}
                                    required={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    multiline={true}
                                    label={Config.lang("DHR_Ghi_chu_sau_thai_san")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.NoteAfterOff}
                                    disabled={loading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => this.handleChange("NoteAfterOff", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <div className={classes.asterisk}>
                            <Attachments
                                isAttachInfo
                                ref={(ref) => (this.attRef = ref)}
                                showButton={true}
                                files={dataOldAttachments}
                                disabled={loading}
                                maxLength={5}
                                uploading={uploading || loading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <IconButton aria-label="view" disabled={loading} onClick={this.onAttachment}>
                        <AttachFileIcon />
                    </IconButton>
                    <ButtonGeneral
                        loading={loading}
                        name={Config.lang("DHR_Luu")}
                        typeButton={"save"}
                        disabled={loading || !Boolean(allowSave) || !hasAttachment || uploading || isSaveSuccess}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={this.onStore}
                    />
                </ModalFooter>
            </Modal>
        );
    }

    componentDidMount = () => {
        this.loadFormData();
        this.loadAttachments();
    };
}

export default compose(
    connect(
        (state) => ({
            getForm: state.W09F2021.getForm,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2021Actions: bindActionCreators(W09F2021Actions, dispatch),
        }),
    ),
    withStyles(styles),
)(W09F2022);
