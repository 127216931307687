/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @update 8/11/2020
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles, Tabs, Tab, Menu, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import { Image, FormGroup, Row, Col } from "react-bootstrap";
import Filter from "../../../filter/filter";
import { Combo } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import { browserHistory } from "react-router";
import W09F2060Popup from "./W09F2060Popup";
import UserImage from "../../../common/user/user-image";

import * as W09F2060Actions from "../../../../redux/W0X/W09F2060/W09F2060_actions";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import _ from "lodash";
import {ButtonIcon, Button} from "diginet-core-ui/components";
import W84F3005 from '../../../W8X/W84/W84F3005/W84F3005';

const styles = (theme) => ({
  divAvatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    overflow: "hidden",
    marginRight: 8
  },
  imgAvatar: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain"
  },
  dateInfo: {
    display: "flex",
    alignItems: "center"
  },
  divText: {
    // fontSize: "14px",
    fontWeight: 500,
    // color: "#575757"
  },
  divDateText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000"
  },
  indicator: {
    bottom: -2,
    height: 5,
    borderRadius: 40,
  },
  avatarCircle:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 46,
    height: 46,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: "1.2rem",
    fontWeight: 600,
    textTransform: "uppercase"
  },
  divider:{
    width: 2,
    height: 11,
    margin: '0 5px'
  }
});

class W09F2060 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iPermission: 0,
      iPermissionDepartment: 0,
      iPermissionProject: 0,
      mode: "add",
      rowData: null,
      typeAdd: 0,
      tabIndex: 0,
      ProDateTo: "",
      ProDateFrom: "",
      anchorEl: null,
      showW09F2060Popup: false,
      dataCboEmployees: {
        rows: [],
        total: 0
      },
      dataCboProjects: {
        rows: [],
        total: 0
      },
      loading: {
        cboEmployees: false,
        cboProjects: false,
      },
      showW84F3005: false
    };
    this.loading = {
      cboEmployees: false,
      cboProjects: false,
    };
    this.filter = {
      Employee: "%",
      Project: "%",
      AppStatusID: "%",
      DepartmentID:"",
      skip: 0,
      limit: 10
    };
    this.filterCboEmployees = {
      timer: null,
      strSearch: "",
      skip: 0,
      limit: 50
    };
    this.filterCboProject = {
      timer: null,
      strSearch: "",
      skip: 0,
      limit: 50
    };
  }

  handleChangeTab = (e, value) => {
    const state = Config.getUrlParams(this.props);
    this.filter = { Employee: "%", Project: "%", AppStatusID: "%", DepartmentID: "", skip: 0, limit: 10 };
    this.setState({ tabIndex: value,ProDateTo: null, ProDateFrom: null }, () => {
      if (state && state.hasOwnProperty("Type")) {
        delete state.Type;
        browserHistory.push({
          pathname: Config.getRootPath() + "W09F2060",
          state: state
        });
      }
      this.loadDataGrid();
    });
  };

  formatDate = (date) => {
    let dateFormatted = "";
    if (date) dateFormatted = "";
    if (moment(date).isValid()) {
        dateFormatted = moment(date).format("DD/MM/YYYY kk:mm:ss");
    }
    return dateFormatted;
  };

  loadPermission = async () => {
    await this.props.generalActions.getPermission("W09F2060", (isPer) => {
      this.setState({iPermission: isPer});
    });
  };

  loadPermissionDepartment = async () => {
    await this.props.generalActions.getPermission("W09F2062", (isPer) => {
      this.setState({iPermissionDepartment: isPer});
    },true);
  };

  loadPermissionProject = async () => {
    await this.props.generalActions.getPermission("W09F2063", (isPer) => {
      this.setState({iPermissionProject: isPer});
    },true);
  };


  loadCboDepartment = () => {
    const param = {
        HostID: "",
        FormID: "W09F2060",
        Language: Config.language || "84",
        DivisionID: Config.getDivisionID(),
    };
    this.props.generalActions.getCboDepartments(param, (err) => {
        if (err) return Config.popup.show("INFO", _.get(err, "message", "") || Config.lang("Loi_chua_xac_dinh"));
    });
  };

  onSearch = () => {
    this.loadDataGrid();
  };

  onChangePageProfile = data => {
    browserHistory.push({
      pathname: Config.getRootPath() + "W09F2000",
      state: { EmployeeID: data.EmployeeID }
    });
  };

  loadRewardCaption = () => {
    this.props.w09f2060Actions.loadRewardCaption({Language: Config.language || "84"}, (error) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  renderEmpProfile = (e) => {
    const { classes } = this.props;
    const { tabIndex } = this.state;
    const { data } = e.row;
    const date = this.formatDate(_.get(data, "ProposalDate", ""));
    let templateImage = "";
    let templateName = "";
    switch (tabIndex) {
        case 0:{
            const departmentName = _.get(data, "DepartmentName", "");
            templateImage = <UserImage data={data} onClick={() => this.onChangePageProfile(data)} />;
            templateName = (
                <>
                    <div className={classes.divText}>
                        <UserName data={data} />
                    </div>
                    <div className={classes.divText}>
                        {departmentName}
                    </div>
                </>
            );
            break;
        }
        case 1:{
            const departmentName = _.get(data, "ProDepartmentName", "");
            templateImage = (
                <div style={{ paddingRight: 8 }}>
                    <div className={classes.avatarCircle}>{departmentName ?  String(data.ProDepartmentName).charAt(0) : ""}</div>
                </div>
            );
            templateName = <div className={classes.divText}>{departmentName}</div>;
            break;
        }
        case 2:{
            const projectName = _.get(data, "ProjectName", "");
            templateImage = (
                <div style={{ paddingRight: 8 }}>
                    <div className={classes.avatarCircle}>{projectName ? String(data.ProjectName).charAt(0) : ""}</div>
                </div>
            );
            templateName = <div className={classes.divText}>{projectName}</div>;
            break;
        }
        default:
            break;
    }

    return (
        <div className={"display_row align-center pdt10 pdb10"}>
            {templateImage}
            <div className={"w75f2000_info"}>
                {templateName}
                <div className={classes.dateInfo}>
                    <Image src={require("../../../../assets/images/icon-calendar.svg")} />
                    <div style={{ paddingLeft: "8px" }} className={"date-text-info"}>
                        {date}
                    </div>
                </div>
            </div>
        </div>
      );
    };

  renderInfo = e => {
    const { data } = e.row;
    const { classes } = this.props;
    const arrDataField = [{
      label: Config.lang("Hinh_thuc_khen_thuong"),
      content: data.ProDisRewardFormName,
      isVisible: _.get(data,"ProDisRewardFormName", "")
    },
    {
      label: Config.lang("Ngay_hieu_luc"),
      content: data.ValidDate,
      isVisible: this.formatDate(_.get(data,"ValidDate", ""))
    },
    {
      label: Config.lang("Ghi_chu"),
      content: data.ProNotice,
      isVisible: _.get(data,"ProNotice", "")
    },
    {
      label: Config.lang("Nguoi_lap"),
      content: data.ProposerName,
      isVisible: _.get(data,"ProposerName", "")
    },
    {
      label: Config.lang("Nguoi_duyet"),
      content: _.join(_.compact([data.ApproverName, data.DepartmentID, this.formatDate(data.ApprovalDate)]), " - "),
      isVisible: _.get(data,"ApproverName", "") || _.get(data, "DepartmentID", "") || _.get(data,"DepartmentID", "")
    },
    {
      label: Config.lang("Ghi_chu_duyet"),
      content: data.ApprovalNotes,
      isVisible: _.get(data,"ApprovalNotes", "")
    },
  ].filter(({isVisible}) => Boolean(isVisible));
    return (
      <div className={"pdt10 pdb10 " + classes.divText}>
          {arrDataField.map((item, i) => <div key={i}>{item.label}: {item.content}</div>)}
      </div>
    );
  };

  renderStatus = e => {
    const { data } = e.row;
    return (
        <div className={"display_row align-center valign-middle"}>
          <Status data={data} />
        </div>
    );
  };

  onAdd = (typeAdd = 0) => {
    this.setState({
      mode: "add",
      showW09F2060Popup: true,
      rowData: null,
      typeAdd,
    }, () => {
      this.handleChangeTab(null, typeAdd);
    });
  };

  onView = (e) => {
    const { data } = e.row;
    this.setState({
      mode: "view",
      rowData: data,
      showW09F2060Popup: true
    });
  };

  onDelete = data => {
    const { ProTransID } = data;
    const {tabIndex} = this.state;
    let Mode = "";
    switch (tabIndex) {
      case 0:
        Mode="employee";
        break;
      case 1:
        Mode="project";
        break;
      case 2:
        Mode="department";
        break;
      default:
        break;
    }
    const param = {
      ProTransID,
      Mode
    };

    Config.popup.show(
      "YES_NO",
      Config.lang("Ban_co_chac_muon_xoa?"),
      () => {
        this.props.w09f2060Actions.deleteDataGrid(param, error => {
          if (error) {
            Config.popup.show("INFO", Config.lang("Loi_chua_xac_dinh"));
            return false;
          } else {
            Config.notify.show(
              "success",
              Config.lang("Xoa_thanh_cong"),
              3000
            );
            this.loadDataGrid();
          }
        });
      }
    );
  };

  handleClickMenuItem = (i) => {
    this.onAdd(i);
    this.onCloseToggleMenu();
  };

  onOpenToggleMenu = (e) => {
    const {iPermissionDepartment,iPermissionProject} = this.state;
    if (iPermissionDepartment > 0 || iPermissionProject > 0) {
      this.setState({anchorEl: e.currentTarget})
    } else {
      this.handleClickMenuItem(0);
    }
  };

  onCloseToggleMenu = (e) => {
    this.setState({anchorEl: null});
  };

  onViewHistory = (e) => {
    this.setState({ showW84F3005: true, rowData: e.data });
  }

  renderAction = e => {
    const { data } = e.row;
    const {tabIndex, iPermissionDepartment, iPermissionProject} = this.state;
    let permission = 0;
    switch (tabIndex) {
      case 0:
        permission = 4;
        break;
      case 1:
        permission = iPermissionDepartment;
        break;
      case 2:
        permission = iPermissionProject;
        break;
      default:
        break;
    }
    permission = Number(permission);
    return (
      <div className={"display_row align-center"}>
        <ButtonIcon
          circular
          viewType={'text'}
          name={'History'}
          onClick={() => this.onViewHistory(e)}
          className={'mgr5'}
        />
        <ButtonIcon
          circular
          viewType={"text"}
          name={"View"}
          className={'mgr5'}
          disabled={Number(data?.OnlyWatch) === 1 ? false : permission <= 1}
          onClick={() => this.onView(e)}
        />
        <ButtonIcon
          circular
          viewType={"text"}
          name={"Delete"}
          disabled={Number(data?.OnlyWatch) === 1 || Number(data?.AppStatusID) !== 0 || permission < 4 }
          onClick={() => this.onDelete(data)}
        />
      </div>
    );
  };

  filterChange = (key, data) => {
    const value = _.get(data,"value", _.get(data,"target.value", ""));
    const keyState = ["ProDateFrom", "ProDateTo"];
    if(keyState.includes(key)){
      this.setState({
          [key]: value
      });
      return;
    }
    this.filter[key] = value;
  };

  renderFilter = () => {
    const {
      getCboAppStatus,
      getCboDepartments,
      classes
    } = this.props;
    const { Project, AppStatusID, Employee, DepartmentID } = this.filter;
    const { ProDateTo, ProDateFrom, dataCboEmployees, dataCboProjects, loading } = this.state;

    return (
      <Filter
        placeholder={Config.lang("Nhap_tu_tim_kiem")}
        openOnFieldClick={true}
        renderFilter={() => {
          return (
            <>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.divText}>
                        {Config.lang("Tu")}
                      </div>
                      <DateBoxPicker
                        dateBoxProps={{ max: ProDateTo ? ProDateTo :  moment() }}
                        style={{ marginLeft: "5px" }}
                        onValueChanged={e => this.filterChange("ProDateFrom", e)}
                        useMaskBehavior={true}
                        showClearButton={true}
                        placeholder={"DD/MM/YYYY"}
                        value={ProDateFrom || ""}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.divText}>
                        {Config.lang("Den").toLowerCase()}
                      </div>
                      <DateBoxPicker
                        dateBoxProps={{ min: ProDateFrom, max: moment() }}
                        style={{ marginLeft: "5px" }}
                        onValueChanged={e => this.filterChange("ProDateTo", e)}
                        useMaskBehavior={true}
                        showClearButton={true}
                        placeholder={"DD/MM/YYYY"}
                        value={ProDateTo || ""}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      label={Config.lang("Trang_thai_duyet")}
                      dataSource={getCboAppStatus}
                      displayExpr={"AppStatusName"}
                      valueExpr={"AppStatusID"}
                      onValueChanged={e => this.filterChange("AppStatusID", e)}
                      showClearButton={true}
                      value={AppStatusID === "%" ? "" : AppStatusID}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                        dataSource={dataCboEmployees}
                        skip={this.filterCboEmployees.skip}
                        limit={this.filterCboEmployees.limit}
                        displayExpr={"EmployeeName"}
                        keyExpr={'EmployeeID'}
                        valueExpr={'EmployeeID'}
                        value={Employee === "%" ? null : Employee}
                        stylingMode={'outlined'}
                        loading={loading.cboEmployees}
                        label={Config.lang('Nhan_vien')}
                        itemRender={(e) => {
                          const {data} = e;
                          if (!data) return null;
                          return data.EmployeeID + ' - ' + data.EmployeeName;
                        }}
                        showClearButton={true}
                        // showCheckBoxMode={"none"}
                        onValueChanged={e => this.filterChange("Employee", e)}
                        onInputChanged={(e) => {
                          this.filterCboEmployees.strSearch = e.target.value;
                          this.filterCboEmployees.skip = 0;
                          this.loadCboEmployees(true);
                        }}
                        onLoadMore={(e) => {
                          this.filterCboEmployees.skip = e.skip;
                          this.filterCboEmployees.limit = e.limit;
                          this.loadCboEmployees();
                        }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                        dataSource={dataCboProjects}
                        skip={this.filterCboProject.skip}
                        limit={this.filterCboProject.limit}
                        displayExpr={'ProjectName'}
                        keyExpr={'ProjectID'}
                        valueExpr={'ProjectID'}
                        value={Project === "%" ? null : Project}
                        loading={loading.cboProjects}
                        stylingMode={'outlined'}
                        label={Config.lang('Du_an')}
                        itemRender={(e) => {
                          const {data} = e;
                          if (!data) return null;
                          return data.ProjectID + ' - ' + data.ProjectName;
                        }}
                        showClearButton={true}
                        // showCheckBoxMode={"none"}
                        onValueChanged={e => this.filterChange("Project", e)}
                        onInputChanged={(e) => {
                          this.filterCboProject.strSearch = e.target.value;
                          this.filterCboProject.skip = 0;
                          this.loadCboProjects(true);
                        }}
                        onLoadMore={(e) => {
                          this.filterCboProject.skip = e.skip;
                          this.filterCboProject.limit = e.limit;
                          this.loadCboProjects();
                        }}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup style={{ marginBottom: "7px" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                  <Combo
                      label={Config.lang("Phong_ban")}
                      dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                      valueExpr={"DepartmentID"}
                      displayExpr={"DepartmentName"}
                      onValueChanged={(e) => this.filterChange("DepartmentID", e)}
                      showClearButton={true}
                      value={DepartmentID}
                      />
                  </Col>
                </Row>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "7px 0",
                }}
              >
                <ButtonGeneral
                    name={Config.lang("Tim_kiem")}
                    typeButton={"search"}
                    size={"large"}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={this.onSearch}
                />
              </div>
            </>
          );
        }}
      />
    );
  };

  componentDidMount = async() => {
    await this.loadPermission();
    if (this.state.iPermission <= 0) return;
    this.loadPermissionDepartment();
    this.loadPermissionProject();
    this.loadRewardCaption();
    this.loadCboEmployees();
    this.loadCboProjects();
    this.loadCboDepartment();
    this.loadDataGrid();
    this.loadCboAppStatus();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    const Type = this.getTypeTab();
    const {iPermissionDepartment,iPermissionProject } = this.state;
    if ((Type || Type === 0) && Type !== this.state.tabIndex) {
      if (Type === 1 && iPermissionDepartment > 0) {
        this.handleChangeTab(null, Type);
      } else if (Type === 2 && iPermissionProject > 0) {
        this.handleChangeTab(null, Type);
      } else if (Type === 0) {
        this.handleChangeTab(null, Type);
      }
    } else {
      const {getDataGrid} = this.props;
      Config.callChildForm({
        FormID: "W09F2060",
        // ID: voucher_id,
        keyExpr: "ProTransID",
        data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
        onLoad: (params) => this.loadDataGrid(params && params.ID, params.Type),
        onAction: (e, data) => this.onView({ row: { data: data } })
      }, this.props);
    }
  }

  getTypeTab = (type) => {
    let {Key01ID: Type} = Config.getUrlParams(this.props);
    if (type) type = Number(type);
    if (Type) Type = Number(Type);
    Type = type || type === 0 ? type : Type;
    return Type;
  };

  loadCboEmployees = (isReset) => {
    const param = {
      Type: "EmployeeID",
      FormID: "W09F2060",
      Language: Config.language || "84",
      skip: this.filterCboEmployees.skip,
      limit: this.filterCboEmployees.limit,
      search: this.filterCboEmployees.strSearch
    };
    this.loading.cboEmployees = true;
    this.setState({loading: this.loading});
    this.props.generalActions.getCboEmployees(param, (error, data) => {
      this.loading.cboEmployees = false;
      this.setState({loading: this.loading});
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboEmployees} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboEmployees: {
            rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadCboProjects = (isReset) => {
    const param = {
      FormID: "W09F2060",
      skip: this.filterCboProject.skip,
      limit: this.filterCboProject.limit,
      search: this.filterCboProject.strSearch
    };
    this.loading.cboProjects = true;
    this.setState({loading: this.loading});
    this.props.generalActions.getCboProjects(param, (error, data) => {
      this.loading.cboProjects = false;
      this.setState({loading: this.loading});
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboProjects} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboProjects: {
            rows: isReset ? rows : dataCboProjects.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadDataGrid = (VoucherID, TypeTab) => {
    const {voucher_id} = Config.getUrlParams(this.props);
    const Type = this.getTypeTab(TypeTab);
    VoucherID = VoucherID ? VoucherID : voucher_id;
    const { ProDateTo, ProDateFrom, tabIndex } = this.state;
    const {Employee, Project, AppStatusID, DepartmentID,limit, skip} = this.filter;
    if ((Type || Type === 0) && Type !== tabIndex) return false;
    const param = {
      TransID: VoucherID || "",
      FormID: "W09F2060",
      Mode: tabIndex,
      DepartmentID,
      Language: Config.language || 84,
      ProDateFrom: ProDateFrom || null,
      ProDateTo: ProDateTo || null,
      EmployeeID: Employee && Employee.EmployeeID ? Employee.EmployeeID : "%",
      ProjectID: Project && Project.ProjectID ? Project.ProjectID : "%",
      AppStatusID,

      skip: skip,
      limit: limit
    };
    this.setState({ gridLoading: true });
    this.props.w09f2060Actions.loadDataGrid(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      this.setState({ gridLoading: false });
    });
  };

  loadCboAppStatus = () => {
    const param = {
      FormID: "W09F2060",
      Language: Config.language || 84
    };

    this.props.w09f2060Actions.loadAppStatus(param, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  onChangePage = page => {
    this.filter.skip = page * this.filter.limit;
    this.loadDataGrid();
  };

  onChangePerPage = perPage => {
    this.filter.skip = 0;
    this.filter.limit = perPage;
    this.loadDataGrid();
  };

  onCloseModal = (isSaved = false) => {
    if(isSaved) this.loadDataGrid();
    this.setState({
      showW09F2060Popup: false
    });
  };

  render() {
    const { getDataGrid, classes: { indicator } } = this.props;
    const { iPermission, gridLoading, showW09F2060Popup, mode, rowData, tabIndex, anchorEl, typeAdd, iPermissionDepartment, iPermissionProject, showW84F3005 } = this.state;
    if (iPermission <= 0) return null;
    const { skip, limit } = this.filter;
    let subTitleModal = [Config.lang("Nhan_vien"),Config.lang("Phong_ban"),Config.lang("Du_an")];
    return (
      <>
        {showW84F3005 && <W84F3005 open={showW84F3005} onClose={() => this.setState({ showW84F3005: false })} FormID="W09F2060" TransID={rowData?.ProTransID ?? ''} />}
        <Modal
          open={showW09F2060Popup}
          maxWidth={"md"}
          fullWidth={true}
          onClose={() => this.onCloseModal(false)}
          title={Config.lang("Lap_de_xuat_khen_thuong") + " " + subTitleModal[(mode === "add" ? typeAdd : tabIndex)].toLocaleLowerCase()}
        >
          <W09F2060Popup
            type={mode === "add" ? typeAdd : tabIndex}
            mode={mode}
            data={rowData}
            onClose={this.onCloseModal}
          />
        </Modal>
        <div className={"hidden"}>{this.renderFilter()}</div>
        <ActionToolbar title={Config.lang("De_xuat_khen_thuong")}>
          <Button
              size={"medium"}
              color={"primary"}
              viewType={"filled"}
              label={Config.lang("Them")}
              startIcon={"AddCircle"}
              onClick={this.onOpenToggleMenu}
          />
           <Menu
              getContentAnchorEl={null}
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={this.onCloseToggleMenu}
             >
             {(iPermissionDepartment > 0 || iPermissionProject > 0) && <MenuItem  onClick={() => this.handleClickMenuItem(0)}>{Config.lang("Nhan_vien")}</MenuItem>}
             {iPermissionDepartment > 0 && <MenuItem onClick={() => this.handleClickMenuItem(1)}>{Config.lang("Phong_ban")}</MenuItem>}
             {iPermissionProject > 0 && <MenuItem onClick={() => this.handleClickMenuItem(2)}>{Config.lang("Du_an")}</MenuItem>}
        </Menu>
        </ActionToolbar>
         <Tabs
          classes={{ indicator }}
          indicatorColor="primary"
          textColor="primary"
          value={tabIndex}
          onChange={this.handleChangeTab}
        >
          <Tab
            key={0}
            label={Config.lang("Nhan_vien")}
          />
           {iPermissionDepartment > 0 && <Tab
            key={1}
            label={Config.lang("Phong_ban")}
          />}
           {iPermissionProject > 0 && <Tab
              key={2}
              label={Config.lang("Du_an")}
            />}
        </Tabs>
        <GridContainer
          style={{ border: "none" }}
          dataSource={getDataGrid && getDataGrid.rows}
          keyExpr={"ProTransID"}
          showRowLines={true}
          showBorders={false}
          showColumnLines={false}
          showColumnHeaders={false}
          noDataText={Config.lang("No_data")}
          onCellClick={this.onDetail}
          loading={gridLoading}
          hoverStateEnabled={true}
          totalItems={getDataGrid && Number(getDataGrid.total)}
          itemPerPage={limit}
          skipPerPage={skip}
          typePaging={"remote"}
          height={Config.getHeightGrid() - 42}
          onChangePage={this.onChangePage}
          onChangePerPage={this.onChangePerPage}
        >
          <Column cellRender={this.renderEmpProfile} width={300} />
          <Column cellRender={this.renderInfo} />
          <Column cellRender={this.renderStatus} width={200} />
          <Column cellRender={this.renderAction} width={150} />
        </GridContainer>
      </>
    );
  }
}

export default compose(
  connect(
    state => ({
      getCboAppStatus: state.W09F2060.getCboAppStatus,
      getDataGrid: state.W09F2060.getDataGrid,
      getCboDepartments: state.general.getCboDepartments,
    }),
    dispatch => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w09f2060Actions: bindActionCreators(W09F2060Actions, dispatch)
    })
  ),
  withStyles(styles, { withTheme: true})
)(W09F2060);
