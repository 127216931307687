/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 2/12/2020
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W09F2010Actions from "../../../../redux/W0X/W09F2010/W09F2010_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import CDN from "../../../CDN";
import withStyles from "@material-ui/core/styles/withStyles";
import { MForm } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from 'lodash';
import moment from "moment";
import { 
    Attachment,
    Avatar,
    Dropdown,
    Typography,
    TextInput,
    Button,
    ModalBody,
    ModalFooter,
    DatePicker,
    Row,
    Col,
} from 'diginet-core-ui/components';

const styles = {
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    inputNoLine:{
        '&>div':{
            pointerEvent:'none',
            '&:before':{
                display:'none'
            }
        }
    }
};

class W09F2011 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            W09F2011: false,
            dataGrid: [],
            disabled: false,
            error: {},
            loading: false,
            uploading: false,
            dataOldAttachments: [],
            dataForm: {
                Employee: null,
                DateLeft: null,
                NoticeDate: moment().format("YYYY-MM-DD"),
                ResignFormID: "",
                Notes: "",
                ReasonID: "",
                ViolateDays: 0,
            },
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            loadEmployees: false,
            disabledCboEmployee: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadCboResignForm = () => {
        const param = { Language: Config.language || "84" };

        this.props.w09f2010Actions.loadCboResignForm(param, error => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboReason = () => {
        const param = { Language: Config.language || "84", TypeID: 2 };

        this.props.w09f2010Actions.getCboReason(param, error => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = () => {
        const { data } = this.props;
        const param = {
            LeaveTransID: data && data.LeaveTransID ? data.LeaveTransID : ""
        };
        this.setState({ loading: true });
        this.props.w09f2010Actions.loadForm(param, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({ EmployeeID: data.EmployeeID });
                this.setState({
                    dataForm: {
                        ...data,
                        Employee: user ? user : data
                    }
                });
            }
        });
    };

    loadRequiredFields = () => {
        const params = {
            FormID: 'W09F2010',
            ModuleID: '09',
            CheckMode: 0
        };
        this.setState({ loading: true });
        this.props.generalActions.getRequiredFields(params, error => {
            this.setState({ loading: false });
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
        });
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        if (key !== "EmployeeID") {
            this._setDataForm(key, e.value);
        } else {
            this._setDataForm("Employee", e.data);
        }
    };

    componentDidMount() {
        const { mode, type, data, dataForm } = this.props;
        this.loadCboResignForm();
        this.loadCboReason();
        this.loadCboEmployees();
        this.loadRequiredFields();
        if (mode === "view") {
            this.loadForm();
            this.loadAttachments();
            if (data && (Number(data.AppStatusID) !== 0)) {
                this.setState({ disabled: true });
            }
        } else {
            if (!type || type === "ESS") {
                this.setState({
                    disabledCboEmployee: true,
                }, () => {
                    const EmployeeID = Config.getHREmployeeID();
                    const user       = Config.getUser({EmployeeID});
                    if (user) {
                        this.setState({
                            dataForm: {
                                ...dataForm,
                                Employee: {
                                    EmployeeID:     user.EmployeeID ? user.EmployeeID : "",
                                    EmployeeName:   user.EmployeeName ? user.EmployeeName : "",
                                    UserPictureURL: user.UserPictureURL ? user.UserPictureURL : "",
                                }
                            }
                        })
                    }
                });
            }
        }
    }

    _setDataForm = (key, value, cb) => {
        if (!key) return false;
        this.setState({
            dataForm: {
                ...this.state.dataForm,
                [key]: value
            }
        }, () => {
            if (cb) cb();
            const {dataForm} = this.state;
            // if onchange datetime picker DateLeft || NoticeDate
            if((key === 'DateLeft' || key === 'NoticeDate') && dataForm.NoticeDate && dataForm.DateLeft){
                this.getViolateDays();
            }
        });
    };

    /**
     * Call api get data ViolateDays in master form
     * no params
     */
    getViolateDays = () => {
        const {dataForm:{DateLeft, NoticeDate, Employee}} = this.state;
        const params = {
            FormID: 'W09F2010',
            Language: Config.language || "84",
            Mode: 0,
            EmployeeID: Employee?.EmployeeID,
            NoticeDate,
            DateLeft
        };
        this.props.w09f2010Actions.getViolateDays(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({
                dataForm: {
                    ...this.state.dataForm,
                    ViolateDays: data?.ViolateDays ?? 0
                }
            })
        });
    };

    setStateErrorText(objValue, key) {
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...(this.state[stateError] ? this.state[stateError] : {}),
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = async () => {
        const {
            mode,
            data,
            getRequiredFields,
        } = this.props;
        const isEdit = mode === "view";
        const { dataForm, dataOldAttachments } = this.state;
        const listDataForm = Object.keys(dataForm);
        let dataRequired = ["EmployeeID", "DateLeft", "NoticeDate"];
        const filterAttachment = getRequiredFields && getRequiredFields.filter(item =>
            item.SqlFieldName === 'Attachment'
        );
        getRequiredFields && getRequiredFields.forEach((field) => {
            if ((field.ValidMode && field.ValidMode === "O"
                && field.SqlFieldName && listDataForm.indexOf(field.SqlFieldName) > -1
                && dataRequired.indexOf(field.SqlFieldName) < 0)
                || ((filterAttachment[0].ValidMode === "O" && _.isEmpty(this.attachments) && _.isEmpty(dataOldAttachments)) ? (field.SqlFieldName === 'Attachment') : '')
            ) {
                dataRequired.push(field.SqlFieldName);
            }
        });

        //validate form..
        const validateRules = [];
        dataRequired && dataRequired.forEach((item) => {
            let rule = {};
            switch (item) {
                case "EmployeeID":
                    rule = {
                        name: "EmployeeID",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : ""
                    };
                    break;
                case "DateLeft":
                    rule = {
                        name: "DateLeft",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.DateLeft ? dataForm.DateLeft : ""
                    };
                    break;
                case "NoticeDate":
                    rule = {
                        name: "NoticeDate",
                        rules: ["isRequired"],
                        value: dataForm && dataForm.NoticeDate ? dataForm.NoticeDate : moment().format("YYYY-MM-DD")
                    };
                    break;
                default:
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: dataForm[item]
                    };
                    break;
            }
            validateRules.push(rule);
        });
        const validateForm = MForm.formValidation(validateRules);
        if (Object.keys(validateForm).length > 0) {
            this.setStateErrorText(validateForm);
            return false;
        } else {
            const dataResUploadFile = await this._uploadFile(this.attachments, true);
            const arrAttachments = this._getAttachments(dataResUploadFile);
            const Language = Config.language || "84";
            const DivisionID = Config.getDivisionID();
            const TranMonth = Config.getHRTransMonth();
            const TranYear = Config.getHRTransYear();
            const params = {
                LeaveTransID: data && data.LeaveTransID ? data.LeaveTransID : "",
                ...dataForm,
                NoticeDate: dataForm && dataForm.NoticeDate ? dataForm.NoticeDate : moment().format("YYYY-MM-DD"),
                EmployeeID: dataForm && dataForm.Employee && dataForm.Employee.EmployeeID ? dataForm.Employee.EmployeeID : "",
                Language,
                DivisionID,
                TranMonth,
                TranYear,
                arrAttachment: JSON.stringify(arrAttachments),
                ViolateDays: dataForm?.ViolateDays ?? 0
            };
            delete params.Employee;

            this.setState({ loading: true });
            this.props.w09f2010Actions[isEdit ? "edit" : "save"](params, (error, data) => {
                this.setState({ loading: false });
                if (error) {
                    let message = Config.lang("Loi_chua_xac_dinh");
                    switch (error.code) {
                        case "F2000E002":
                            message = "Mode " + Config.lang("Bat_buoc");
                            break;
                        case "F2000E003":
                            message = "LeaveTypeID " + Config.lang("Bat_buoc");
                            break;
                        case "F2000E004":
                            message = "IsRegisterType " + Config.lang("Bat_buoc");
                            break;
                        case "F2000E001":
                            message = Config.lang("Luu_khong_thanh_cong");
                            break;
                        default:
                            break;
                    }
                    Config.popup2.show("INFO", message);
                    return false;
                }
                else if (data.Status && data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                } else {
                    this._removeCDN();
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    this.props.onClose(true);
                }
            }
            );
        }

    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    _uploadFile = (files, isAsync, cb) => {
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = (file) => {
        const dataFile = file?.data?.paths || [];
        const listAttachments = Config.helpers.getFileInfomations(dataFile);
        const {dataOldAttachments} = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt : att.fileName ? att.fileName.split('.').pop() : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: data && data.LeaveTransID ? data.LeaveTransID : "",
        };
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e?.allNewAttached || [];
        if (e?.removedAttached?.length > 0) {
            this.deletedFile = [...e.removedAttached];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    // Type === 1 => MSS
    loadCboEmployees = (isReset) => {
        const { mode, type } = this.props;
        const EmployeeID = Config.getHREmployeeID();
        const user       = Config.getUser({EmployeeID});
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2010",
            Mode: mode === "add" ? 2 : 0,
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: type ? this.filterCboEmployees.strSearch : (user?.EmployeeID || ""),
            search2: type ? this.filterCboEmployees.strSearch : "",
            // searchUC: type ? this.filterCboEmployees.strSearch : "" //API báo ko truyền trường này
        };
        this.setState({ loadEmployees: true });
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({ loadEmployees: false });
            if (error) {
                Config.popup2.show("ERROR", error);
                return false;
            }
            if (data) {
                const { dataCboEmployees } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    render() {
        let {
            getCboResignForm,
            getCboReason,
            mode,
            getRequiredFields,
        } = this.props;
        const {
            uploading,
            dataForm,
            error,
            loading,
            dataOldAttachments,
            disabled,
            dataCboEmployees,
            loadEmployees,
            disabledCboEmployee,
        } = this.state;
        const dateLeftValidMode = getRequiredFields.find(p=> p.SqlFieldName === "DateLeft")?.ValidMode;
        const noticeDateValidMode = getRequiredFields.find(p=> p.SqlFieldName === "NoticeDate")?.ValidMode;

        return (
            <>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Dropdown
                                required
                                error={error && error["EmployeeID"]}
                                valueObjectDefault={
                                    typeof dataForm?.Employee === "object" ? dataForm.Employee : null
                                }
                                dataSource={dataCboEmployees.rows}
                                displayExpr={"EmployeeName"}
                                subText={"EmployeeID"}
                                valueExpr={"EmployeeID"}
                                loading={loadEmployees}
                                total={dataCboEmployees.total}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                allowSearch
                                className={"mgt2x"}
                                renderSelectedItem={(data) => {
                                    const {data: item} = data;
                                    return (
                                        <div className={"display_row align-center"}>
                                            <Avatar
                                                hoverAble
                                                readOnly
                                                width={24}
                                                height={24}
                                                data={{
                                                    [Config.lang('Phong_ban')]:item?.DepartmentName,
                                                    [Config.lang('Du_an')]:item?.ProjectName,
                                                    [Config.lang('Chuc_vu')]:item?.DutyName,
                                                    [Config.lang('Ngay_vao_lam')]:Config.convertDate(item?.DateJoined, '------', "DD/MM/YYYY")
                                                }}
                                                src={`${Config.getUserPicture(item?.UserPictureURL)}`}
                                            />
                                            <Typography
                                                hoverTooltip
                                                className={"mgl5"}
                                                lineClamp={1}
                                            >{`${item?.EmployeeID} - ${item?.EmployeeName}`}</Typography>
                                        </div>
                                    );
                                }}
                                value={dataForm?.Employee?.EmployeeID}
                                disabled={mode === "view" || loading || disabled || disabledCboEmployee}
                                label={Config.lang("Nhan_vien")}
                                inputProps={{
                                    readOnly: disabled,
                                }}
                                iconExpr={{
                                    key: "UserPictureURL",
                                    prefix: Config.getCDNPath(),
                                }}
                                onChange={(e) => this.handleChange("EmployeeID", e)}
                                onInput={(e) => {
                                    this.filterCboEmployees.skip = 0;
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextInput
                                label={Config.lang("So_ngay_vi_pham")}
                                readOnly
                                value={dataForm?.ViolateDays ?? 0}
                                className={"mgt2x"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                error={error && error["DateLeft"]}
                                label={Config.lang("Ngay_bat_dau_nghi_viec")}
                                placeholder={Config.lang("Chon_ngay")}
                                required
                                value={dataForm.DateLeft}
                                min={Config.menuType === 0 && dateLeftValidMode === "F" ? moment().add(1, "day").format("YYYY-MM-DD") : null}
                                onChange={e => this.handleChange("DateLeft", e)}
                                disabled={disabled || loading}
                                displayFormat="DD/MM/YYYY"
                                className="mgt4x"
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <DatePicker
                                error={error && error["NoticeDate"]}
                                label={Config.lang("Ngay_nop_don")}
                                placeholder={Config.lang("Chon_ngay")}
                                required={Config.menuType === 0 ? noticeDateValidMode === "C" : true}
                                value={dataForm.NoticeDate ?? moment().format("YYYY-MM-DD")}
                                defaultValue={moment().format("YYYY-MM-DD")}
                                onChange={e => this.handleChange("NoticeDate", e)}
                                disabled={disabled || loading || (Config.menuType === 0 ? noticeDateValidMode === "D" : false)}
                                displayFormat="DD/MM/YYYY"
                                className="mgt4x"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                error={error && error["ResignFormID"]}
                                dataSource={getCboResignForm}
                                displayExpr={"ResignFormName"}
                                valueExpr={"ResignFormID"}
                                disabled={disabled || loading}
                                value={dataForm.ResignFormID}
                                label={Config.lang("Hinh_thuc_nghi_viec")}
                                allowSearch={false}
                                onChange={e =>
                                    this.handleChange("ResignFormID", e)
                                }
                                className="mgt5x"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Dropdown
                                error={error && error["ReasonID"]}
                                dataSource={getCboReason}
                                displayExpr={"ReasonName"}
                                valueExpr={"ReasonID"}
                                disabled={disabled || loading}
                                allowSearch={false}
                                value={dataForm.ReasonID}
                                label={Config.lang("Ly_do")}
                                onChange={e =>
                                    this.handleChange("ReasonID", e)
                                }
                                className="mgt4x mgb2x"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextInput
                                error={error && error["Notes"]}
                                label={Config.lang("Ghi_chu")}
                                value={dataForm?.Notes || ""}
                                disabled={disabled || loading}
                                onChange={e => this.handleChange("Notes", e.target)}
                                className="mgt4x mgb2x"
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                error={error && error["Attachment"]}
                                ref={ref => this.attRef = ref}
                                allowSort={false}
                                maxFile={5}
                                maxSize={"5mb"}
                                multiple
                                unitSize={"mb"}
                                data={dataOldAttachments}
                                disabled={disabled || loading || uploading}
                                onChange={this.onChangeAttachments}
                                uploadErrorInfo={{
                                    existingFile: Config.lang('File_da_duoc_dinh_kem'),
                                    maxFile     : Config.lang('File_vuot_qua_so_luong_cho_phep'),
                                    maxSize     : Config.lang('File_vuot_qua_dung_luong_cho_phep'),
                                    fileType    : Config.lang("File_khong_dung_dinh_dang"),
                                }}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="display_row">
                    <div>
                        <Button
                            label={Config.lang("Luu")}
                            startIcon={"save"}
                            disabled={loading || disabled || uploading}
                            style={{ textTransform: "uppercase" }}
                            size={"medium"}
                            viewType="filled"
                            color={"info"}
                            onClick={this.onSave}
                        />
                    </div>
                </ModalFooter>
            </>
        );
    }
}

W09F2011.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,

    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getLeaveTypes: state.W09F2010.getLeaveTypes,
            loadForm: state.W09F2010.loadForm,
            getCboDisRewardForm: state.W09F2010.getCboDisRewardForm,
            getCboProDisRewardLevel: state.W09F2010.getCboProDisRewardLevel,
            getCboDisViolationActGroup: state.W09F2010.getCboDisViolationActGroup,
            getCboDisViolationAct: state.W09F2010.getCboDisViolationAct,
            getRewardCaption: state.W09F2010.getRewardCaption,
            getForm: state.W09F2010.getForm,
            getCboResignForm: state.W09F2010.getCboResignForm,
            getCboReason: state.W09F2010.getCboReason,
            getRequiredFields: state.general.getRequiredFields,
            getAttachmentsByTransID: state.general.getAttachmentsByTransID
        }),
        dispatch => ({
            w09f2010Actions: bindActionCreators(W09F2010Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W09F2011);
