import React                                                 from "react";
import {bindActionCreators, compose}                         from "redux";
import {withStyles, FormControl, Input, InputLabel} from "@material-ui/core";
import PropTypes            from 'prop-types';
import ButtonGeneral        from "../../../common/button/button-general";
import Config               from "../../../../config";
import {connect}            from "react-redux";
import * as W09F2020Actions from "../../../../redux/W0X/W09F2020/W09F2020_actions";
import _                    from "lodash";
import FormHelperText       from "@material-ui/core/FormHelperText";
import PopoverAction        from "../../../grid-container/popover-action";

const styles = theme => {
    return {
        card: {
            width: "590px",
            height: "178px",
            padding: "37px 28px 0px 28px",
            [theme.breakpoints.down('xs')]: {
                width: "300px",
                height: "170px",
            },
        },
        input: {
            padding: '0px 0px 21px 21px'
        },
        underline: {
            paddingTop: '10px'
        },
        shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    }
};


class PopoverW09F2020 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            loading: false,
        };
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({data: this.props.data});
        }
    }

    onSave = async () => {
        const {funcLoadGrid} = this.props;
        const {notes, data} = this.state;
        const params = {
            MaternityTransID: _.get(data, "MaternityTransID", ""),
            Note: notes,
        };
        // setLoading(true);
        this.setState({loading: true});
        await this.props.W09F2020Actions.updateCancel(params, (errors, data) => {
            this.setState({loading: false});
        // setLoading(false);
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
            if (data) {
                if (data.Status === 0) {
                    Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 3000);
                    if (this.props.onClose) this.props.onClose();
                    funcLoadGrid && funcLoadGrid();
                } else {
                    const msg = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                    return false;
                }
            }       
        });

    };

    onOpen = (target, data) => {
        const {anchorEl} = this.props;
        if (this.popover) {
            target = target ? target : anchorEl;
            this.setState({
                data: data
            });
            this.popover.instance.show(target);
        }
    };

    onClose = () => {
        if (this.popover) this.popover.instance.hide();
    };

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value
        })
    };

    render() {
        const { type, classes} = this.props;
        const {notes, errorNote} = this.state;

        return (
            <React.Fragment>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    deferRendering={false}
                    shading={false}
                    style={{zIndex: 1300}}
                    onHiding={() => this.setState({notes: "" })}
                >
                    <div className={classes.card}>
                        <FormControl fullWidth={true} error={!!errorNote && !notes} style={{padding: "0px 0px 21px 0px"}}>
                            <InputLabel
                                color={type === "check" ? "primary" : "secondary"}
                                shrink={true}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 200,
                                }}
                                classes={{shrink: classes.shrink}}
                            >
                                {Config.lang("DHR_Ly_do_huy_phieu")}
                            </InputLabel>
                            <Input fullWidth={true} classes={{underline: classes.underline}}
                                   color={type === "check" ? "primary" : "secondary"}
                                   value={notes}
                                   onChange={this.onNoteChange}
                            >
                            </Input>
                            <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                        </FormControl>
                        <ButtonGeneral
                            name={Config.lang("DHR_Huy_phieu")}
                            color={"danger"}
                            variant={"text"}
                            className="pull-right"
                            style={{textTransform: 'uppercase'}}
                            size={"large"}
                            onClick={()=>this.onSave()}
                        />
                    </div>
                </PopoverAction>
            </React.Fragment>
        );
    }

}

PopoverW09F2020.propTypes = {
    className: PropTypes.string,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
};

export default compose(connect((state) => ({
        saveData: state.W09F2020.saveData,
        loadGrid: state.W09F2020.loadGrid
    }),
    (dispatch) => ({
        W09F2020Actions: bindActionCreators(W09F2020Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(PopoverW09F2020);